.select {
  position: relative;
  width: 178px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: $dark;
  user-select: none;
}

.select__default {
  @include visually-hidden;
}

.select__button {
  position: relative;
  padding: 0 25px 0 8px;
  width: 100%;
  overflow: hidden;
  border: 1px solid $gray-ghost;
  border-radius: 5px;
  line-height: 38px;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
  background-color: $white;
  transition: border-color 200ms;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    right: 9px;
    top: 15px;
    display: inline-block;
    width: 14px;
    height: 9px;
    line-height: 1;
    background-image: url("data:image/svg+xml;utf8,<svg width='14' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.58.327L.4 1.507l6.6 6.6 6.6-6.6-1.18-1.18L7 5.747 1.58.327z' fill='grey'/></svg>");
  }

  &:hover,
  &:focus,
  &.open {
    outline-color: $dark-cyan;
    border-color: $gray-dolphin;

    &::after {
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.58.327.4 1.507l6.6 6.6 6.6-6.6-1.18-1.18L7 5.747 1.58.327z' fill='%23181818'/%3E%3C/svg%3E");
    }
  }

  &.open {
    &::after {
      transform: rotate(-180deg);
    }
  }
}

.select__list {
  position: absolute;
  left: 0;
  top: calc(40px + 4px); // btn-height + margin
  z-index: 1;
  display: none;
  margin-top: 4px;
  padding-bottom: 8px;
  width: 100%;
  max-height: 230px;
  overflow-y: auto;
  border: 2px solid $dark-cyan;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(34, 34, 34, 0.1);
  background-color: $white;

  &:empty {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }

  &.open {
    display: block;
  }
}

.select__option {
  position: relative;
  padding: 8px 7px;
  line-height: 1.1;
  color: #181818;
  cursor: pointer;

  &:hover {
    color: $white;
    background-color: $dark-cyan;
  }

  &.checked {
    font-weight: 600;
    color: $dark;

    &:hover {
      font-weight: 400;
      color: $white;
      background-color: $dark-cyan;
    }
  }
}

.select__empty-result {
  display: block;
  margin: auto;
  padding: 48px 5px 64px;
  max-width: 300px;
  font-size: 16px;
  line-height: 1.4;
  text-align: center;
  color: $gray-dolphin;
}

// disabled
.select.disabled {
  color: $gray-pearl;
  cursor: not-allowed;

  .select__button {
    border-color: $gray-pearl;
    color: $gray-pearl;
    pointer-events: none;

    &::after {
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.58.327.4 1.507l6.6 6.6 6.6-6.6-1.18-1.18L7 5.747 1.58.327z' fill='%23ADADAF'/%3E%3C/svg%3E");
    }
  }

  .select__list {
    display: none;
  }
}

.select--sm {
  width: 178px;
  font-size: 12px;

  .select__button {
    padding-right: 21px;
    padding-left: 4px;
    line-height: 30px;

    &::after {
      right: 6px;
      top: 12px;
    }
  }

  .select__list {
    top: calc(32px + 4px);
  }

  .select__option {
    padding: 8px 4px;
  }
}

.select--lg {
  width: 189px;
  font-size: 16px;

  .select__button {
    line-height: 50px;

    &::after {
      top: 22px;
    }
  }

  .select__list {
    top: calc(54px + 4px);
  }

  .select__option {
    &:first-child {
      margin-top: 8px;
    }
  }
}

.select--search {
  width: 323px;

  .select__button {
    padding: 0 50px 0 16px;

    &::after {
      display: none;
    }

    &:hover,
    &:focus,
    &.open {
      outline: none;
    }

    &.open + .select__button-search {
      border-radius: 4px 0 0 4px;
      transform: rotate(-180deg);
    }
  }

  .select__button-search {
    position: absolute;
    right: 1px;
    top: 1px;
    margin: 0;
    padding: 0;
    width: 48px;
    height: 38px;
    border-radius: 0 4px 4px 0;
    background-image: url("data:image/svg+xml;utf8,<svg width='14' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.58.327L.4 1.507l6.6 6.6 6.6-6.6-1.18-1.18L7 5.747 1.58.327z' fill='grey'/></svg>");
    background-position: center;
    background-repeat: no-repeat;
    background-color: $gray-whisper;
  }
}

.select--search-lg {
  .select__button {
    line-height: 48px;
  }

  .select__button-search {
    height: 48px;
  }

  .select__list {
    top: calc(50px + 4px);
  }
}