.a-di-vind {
  position: fixed;
  z-index: 9999999;
  width: 100%;
  height: 100%;
  background-color: rgba(31, 31, 31, 0.5);
}

.a-di-non-vind {
  z-index: 1001;
  margin: 0 auto;
  width: 100%;
  height: auto;
  max-width: 100%;
  border-radius: 6px;
  background-color: $white;

  &:has(.aic-modal) {
    border-radius: 16px;
    background-color: #fbfcfa;

    @include media($md) {
      height: 100%;
      border-radius: 0;
    }
  }

  .a-di-top {
    padding: 10px 20px;
    border-radius: 6px 6px 0 0;
    background-color: $green-250;

    p {
      margin: 0;
      margin-right: 40px;
      color: $white;
    }

    div.a-di-r,
    p.a-di-r {
      position: relative;
      float: right;
      margin: 0;
      margin-top: -5px;

      a {
        display: inline-block;
        padding: 5px 10px;
        border-radius: 4px;
        color: $white;

        &:hover {
          text-decoration: none;
          color: inherit;
          background-color: $green-250;
        }

        .a-x {
          right: 0;
          top: 4px;
          opacity: 0.8;
          margin: 0;

          &:hover {
            opacity: 1;
          }
        }

        &.a-non-b {
          padding: 0;
          background-image: none;

          &:hover {
            text-decoration: none;
            color: inherit;
            background-image: none;
          }
        }
      }

      ul.a-di-auto {
        // padding: 5px 10px 5px 5px;
        position: absolute;
        right: 65px;
        display: inline-block;
        margin-top: 0;
        padding: 0;
        width: 120px;
        height: 20px;
        vertical-align: top;
        overflow: hidden;
        border-radius: 4px;
        background-color: $green-250;
        cursor: pointer;

        &:hover {
          position: absolute;
          z-index: 9;
          height: 121px;
        }

        li {
          display: block;
          margin-bottom: 5px;

          a {
            display: block;
            width: 100%;

            &:hover {
              background-color: $green-250;
            }
          }

          &.a-pading {
            margin-bottom: 10px;
            text-align: center;
          }
        }
      }
    }
  }

  .a-otvet-tabl {
    padding: 25px;
  }

  @media #{$max-w-sm} {
    z-index: 9999999;
    max-height: 100vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    input {
      outline: none;
    }
  }

  @media #{$max-w-sm} {
    box-shadow: 0 0 8px 5px rgba(225, 235, 227, 0.38) inset;
    background-size: 13%;
  }

  @media #{$max-w-sm} {
    margin: 0 !important;
    width: 100% !important;
    max-width: 100%;
    border-radius: 0;

    .a-di-top {
      border-radius: 0;

      .dr-di-l {
        margin-right: 70px;
        overflow: hidden;

        b {
          float: left;
          margin-right: 10px;
        }

        span {
          float: left;
        }
      }
    }

    .a-info-fechka {
      display: block;
      max-width: 100%;
    }

    .a-wiz-center .row {
      input,
      select,
      textarea {
        width: 100%;
      }
    }

    .a-wiz-center .blok label.a-label {
      width: calc(100% - 60px);
    }

    .buttom {
      padding: 6px;
      max-width: 100%;
      white-space: normal;
    }

    .a-dopinfo-fechka {
      display: block;
      max-width: 100%;
    }
  }

  @media (max-width: 400px) {
    a.a-fechka-dobavit {
      float: left;
      margin-top: -10px;
      padding: 6px 15px;
    }

    .a-wiz-center .blok label.a-label {
      padding-top: 0;
    }
  }
}

.tabs-login {
  position: relative;
  box-shadow: 0 0 8px 5px rgba(225, 235, 227, 0.38) inset;

  h3 {
    margin: 0;
    text-align: center;
  }

  hr {
    margin-top: 15px;
    margin-bottom: 20px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f3f3ff;
    border-bottom-color: $white;
  }

  .a-form-login {
    margin: 0 auto 15px;
    padding: 15px;
    width: 720px;
    max-width: 100%;
    border: 1px solid #d1d1d1ff;
    border-radius: 4px;
    box-shadow: 0 4px 6px -2px #b5b3b3, 0 0 0 12px rgba($white, 0.6);
    background-color: $white;

    .form {
      form {
        ul {
          margin: 5px 0 0;
          padding-left: 14px;

          li {
            margin-bottom: 5px;

            a {
              color: $blue-300;
            }
          }
        }

        .column_2_1_1 {
          p.note {
            float: right;
            margin-right: 10px;
            color: #b8b8b8;
          }
        }

        label {
          display: inline-block;
          padding-right: 20px;
          width: 100px;
          text-align: right;
        }

        input,
        select {
          display: inline-block;
          width: 200px;
          height: 36px;

          &.buttom {
            float: right;
            margin-top: 0;
            margin-right: 9px;
          }
        }

        input[type="checkbox"] {
          display: inline-block;
          width: 20px;
        }

        p {
          margin-bottom: 0;
        }
      }
    }

    @media #{$max-w-sm} {
      label {
        margin-bottom: 5px;
        width: auto !important;
      }

      select,
      input:not(.buttom) {
        width: 100% !important;
      }

      .buttons {
        text-align: center;

        .buttom {
          float: none !important;
        }
      }
    }
  }

  .view {
    padding: 15px;
  }

  ul {
    &.tabs {
      position: relative;
      display: block;
      margin: 0 auto 10px;
      padding: 15px 0 6px;
      width: 394px;
      height: 67px;

      li {
        list-style: none;

        &:first-of-type a {
          margin-right: 10px;
        }
      }

      a {
        float: left;
        margin: 0 0 10px;
        padding: 9px 4px;
        width: 192px;
        height: 36px;
        border: 0;
        border-radius: 4px;
        white-space: nowrap;
        text-align: center;
        color: $white;
        background: rgba($white, 0.3);

        &:hover {
          text-decoration: none;
          color: $white;
          background: rgba($white, 0.2);
        }

        &.active {
          position: relative;
          text-shadow: 0 1px 1px rgba($white, 0.5);
          color: #333f;
          background-color: $white;

          &::before {
            content: "";
            position: absolute;
            left: 42%;
            top: 52px;
            width: 0;
            height: 0;
            border-top: 15px solid $green-250;
            border-right: 15px solid rgba($white, 0);
            border-bottom: 15px solid rgba($white, 0);
            border-left: 15px solid rgba($white, 0);
          }

          &:hover {
            color: #333f;
            background-color: $white;
            cursor: context-menu;
          }
        }
      }

      @media #{$max-w-sm} {
        width: 192px;
        height: 110px;

        &::before {
          content: "";
          position: absolute;
          left: 42%;
          bottom: -30px;
          width: 0;
          height: 0;
          border-top: 15px solid $green-250;
          border-right: 15px solid rgba($white, 0);
          border-bottom: 15px solid rgba($white, 0);
          border-left: 15px solid rgba($white, 0);
        }

        a.active::before {
          display: none;
        }

        li:first-of-type a {
          margin: 0 0 8px;
        }
      }
    }
  }

  &.dr-tabs-login {
    padding: 50px 0 35px;
  }

  @media #{$max-w-sm} {
    padding-bottom: 60px;
    box-shadow: none;
    background-image: none;
  }
}

.dr-login-reg {
  position: absolute;
  display: block;
  width: 100%;
  height: 67px;
  background-color: $green-250;

  @media #{$max-w-sm} {
    height: 110px;
  }
}

.dr-close-modal {
  position: absolute;
  right: 15px;
  top: 15px;
  padding: 0;
  width: 20px;
  height: 20px;
  border: none;
  background-color: transparent;
  transition: transform 0.25s ease-out;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
    height: 1px;
    background-color: $white;
    transform: rotate(45deg);
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
    height: 1px;
    background-color: $white;
    transform: rotate(-45deg);
  }

  &:hover {
    transform: rotate(180deg);
  }
}

.dg-sb-wrapper {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 4px;
}

.dg-wrapper__white {
  background-color: $white;
}

.ta-registation__title {
  margin-bottom: 14px;
  font-weight: 700;
  text-align: center;
}

.error input,
.error select,
.error textarea,
input.error,
select.error,
textarea.error,
.form input.error {
  border: 1px solid $red-250;
}

.errorSummary,
.errorMessage {
  padding: 10px 0;
  font-size: 12px;
  line-height: 18px;
  color: $red-700;

  p {
    margin: 3px 0;
  }

  ul {
    margin: 0;
    padding: 0 16px;

    li {
      list-style-type: disc;
      font-weight: 900;

      a {
        color: $blue-300;
      }
    }
  }
}

.st-input__title,
label.st-input-label {
  display: inline-block;
  margin-bottom: 4px;
  font-weight: 700;
}

@media (min-width: 501px) {
  .ta-registation {
    display: flex;
  }
}

@media (min-width: 501px) {
  .ta-registation__box:first-child {
    margin-right: 14px;
  }
}

@media (min-width: 501px) {
  .ta-registation__box {
    flex: 1;
  }
}

.ta-registation__note {
  font-size: 12px;
}

.kr-login {
  margin: auto;
  max-width: 300px;
}

.ta-registation__lost-pass {
  padding-bottom: 14px;
  border-bottom: 1px solid $grey-125;
  text-align: center;
}

.ta-registation__sub-title {
  text-align: center;
  color: $grey-450;
}
