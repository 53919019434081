.balance-box {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px 24px;
  border-radius: 8px;
  box-shadow: 0 5px 4px 2px rgba(0, 0, 0, 0.2);
  background-color: #fff;

  @include media($md) {
    flex-flow: column;
  }

  &__yellow {
    background-color: #fffce7;
  }

  &__sum {
    span {
      font-weight: 700;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 235px);

    @include media($md) {
      margin-top: 15px;
      width: 100%;
    }
  }

  &__hours {
    margin-bottom: 16px;
    font-size: 18px;

    span {
      font-weight: 700;
      font-size: 24px;
      color: #f60;
    }
  }

  &__subtitle {
    display: block;
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.2;
  }

  &__discount {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
}
