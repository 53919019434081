.link {
  display: inline-flex;
  align-items: center;
  appearance: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  color: #267f8c;
  cursor: pointer;

  &:hover {
    color: #259de3;
  }

  &:active {
    color: #267f8c;
  }

  svg {
    height: 16px;
    min-width: 16px;
  }

  &--disabled {
    color: #c8c8c9;
    cursor: not-allowed;
    user-select: none;

    &:hover {
      color: #c8c8c9;
    }
  }

  &--underline {
    text-decoration: underline;

    &:active,
    &:focus {
      text-decoration: none;
    }
  }

  &--icon {
    svg {
      margin-right: 4px;
    }

    &-right {
      svg {
        order: 1;
        margin: 0 0 0 4px;
      }
    }
  }

  &--xs {
    font-size: 12px;
  }

  &--sm {
    font-size: 14px;
  }

  &--lg {
    font-size: 16px;
  }

  &--xl {
    font-size: 18px;
  }
}
