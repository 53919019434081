@import "../helpers/variables";
@import "../helpers/mixins";

.site-rating-button {
  position: fixed;
  right: 24px;
  bottom: 0;
  z-index: 1030;
  padding: 12px;
  outline: none;
  border: none;
  border-radius: 6px 6px 0 0;
  box-shadow: -2px 2px 11px rgba(0, 0, 0, 0.08);
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #57a216;
  background-color: #fff;

  @include media($xl) {
    right: 0;
    bottom: calc(50% + 60px);
    transform: rotate(-90deg) translate3d(0, 2px, 0);
    transform-origin: right bottom;
  }

  @media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: $sm) {
    bottom: calc(50% + 100px);
  }
}
