@keyframes showHide {
  0%,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}
