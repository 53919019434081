$black: #000;
$white: #fff;
$inputColor: #999;
$borderColor: #d9d9d9;
$borderColorActive: #e6b900;
$radius: 6px;
$activeColor: #ffcd00;
$btnColor: #1987c4;
$grayText: #666;
$lightBlue: #e0f1fd;
$red: #f00;
$orange: #ff8a00;

.possibility {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.possibility__item {
    position: relative;
    margin-bottom: 20px;
    border-radius: $radius;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    color: $black;
    background: $white;
}

.possibility__close {
    position: absolute;
    right: 7px;
    top: 3px;
    width: 10px;
    height: 10px;
    color: $black;

    &:hover {
        color: $black;
    }
}

.possibility__item--active {
    background: $activeColor;

    .possibility__block {
        height: auto;
    }
}

.possibility__item--new-homeworks {
    .possibility__info {
        padding: 30px 20px 20px;
    }

    .possibility__title {
        padding: 13px 20px;
    }
}

.possibility__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $borderColorActive;

    .possibility__title {
        border-bottom: none;
    }
}

.possibility__online {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    margin-top: 10px;
    margin-right: 20px;
    border-radius: $radius;
    padding: 3px 5px;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.35;
    color: $white;
    background-color: $red;

    img {
        flex-shrink: 0;
        flex-basis: 10px;
        margin-right: 5px;
        animation-name: onlineOccupation;
        animation-duration: 3s;
        animation-iteration-count: infinite;
    }
}

.possibility__title {
    border-bottom: 1px solid $borderColor;
    padding: 30px 20px 20px;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.25;
    text-transform: uppercase;
}

.possibility__new {
    display: inline-block;
    margin-top: 5px;
    margin-right: 15px;
    margin-bottom: 5px;
    border-radius: $radius;
    padding: 3px 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.4;
    color: $white;
    background-color: $orange;
}

.possibility__block {
    display: flex;
    align-items: center;
    padding: 20px;
    height: calc(100% - 60px);
    box-sizing: border-box;
}

.possibility__block--flex-start {
    align-items: flex-start;
}

.possibility__icon {
    flex-shrink: 0;
    flex-basis: 94px;
    margin-right: 20px;
}

.possibility__occupation {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.6;
    text-transform: uppercase;
}

.possibility__subtitle {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.4;
}

.possibility__subtitle--new {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.6;
    text-transform: uppercase;
    color: $orange;
}

.possibility__text {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.375;
    color: $grayText;
}

.possibility__btn {
    display: inline-block;
    border-radius: $radius;
    padding: 7px 20px;
    font-weight: 700;
    font-size: 13px;
    line-height: 1.4;
    text-transform: uppercase;
    color: $btnColor;
    background-color: $lightBlue;

    &:hover {
        color: $btnColor;
        background-color: darken($lightBlue, 10%);
    }

    &--disabled {
        color: $black !important;
        background-color: $inputColor !important;
        cursor: not-allowed;
    }
}

.possibility__info--padding {
    padding: 20px;
}

.possibility__list {
    font-size: 16px;
    line-height: 1.5;

    li {
        position: relative;
        margin-bottom: 8px;
        padding-left: 28px;

        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            border-radius: 50%;
            width: 8px;
            height: 8px;
            background-color: $btnColor;
            transform: translateY(-50%);
        }
    }

    a {
        font-weight: 600;
        color: $btnColor;
    }
}

.possibility__subject {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.4;
    text-transform: uppercase;
}

.possibility__teacher {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.possibility__avatar {
    flex-shrink: 0;
    flex-basis: 36px;
    margin-right: 14px;
}

.possibility__teacher-info {
    p {
        font-weight: 600;
        font-size: 16px;
        line-height: 1.375;
    }

    span {
        display: block;
        font-weight: 700;
        text-transform: uppercase;
    }
}

.possibility__connect {
    display: inline-block;
    border-radius: $radius;
    padding: 7px 20px;
    font-weight: 700;
    font-size: 13px;
    line-height: 1.4;
    text-transform: uppercase;
    color: $white;
    background-color: $btnColor;

    &:hover {
        background-color: darken($btnColor, 10%);
    }
}

@keyframes onlineOccupation {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@media (max-width: 1100px) {
    .possibility__icon {
        flex-basis: 90px;
        margin-right: 15px;
    }
}

@media (max-width: 880px) {
    .possibility__block {
        flex-wrap: wrap;
        align-items: flex-start;
        height: auto;
    }

    .possibility__icon {
        flex-basis: 100%;
        margin-right: 0;
        margin-bottom: 10px;
        text-align: center;
    }

    .possibility__info {
        flex-basis: 100%;
    }

    .possibility__top {
        justify-content: flex-start;

        .possibility__title {
            padding-right: 5px;
        }
    }

    .possibility__online {
        width: 30px;

        span {
            display: none;
        }
    }
}

@media (max-width: 640px) {
    .possibility__item {
        flex-basis: 100%;
    }
}

#d-ask-msg-popup {
    @media (max-width: 990px) {
        display: none;
    }
}