html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

ul,
ol,
fieldset {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

ul,
li {
  list-style: none;
}

img {
  display: inline-block;
  max-width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

input,
button,
textarea,
select {
  font: inherit;
}

button {
  border-color: transparent;
  cursor: pointer;
}

address {
  font-style: normal;
}

mark {
  background-color: transparent;
}
