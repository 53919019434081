.pager-pagination {
  display: flex;
  justify-content: center;

  ul {
    display: flex;
    align-items: center;

    @include media($md) {
      overflow-x: auto;
    }
  }

  .first,
  .last {
    display: none;
  }

  .previous,
  .next {
    width: 40px;
    height: 40px;
    border: 1px solid $accent;
    border-radius: 5px;
    font-size: 0;
    text-indent: -9999px;
    background-position: center;
    background-repeat: no-repeat;

    &:hover {
      background-position: center;
      background-repeat: no-repeat;
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
    }

    &.hidden {
      display: none;
    }
  }

  .previous {
    margin-right: 22px;
    margin-left: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m17.51 20.13-1.77 1.77-9.9-9.9 9.9-9.9 1.77 1.77L9.38 12l8.13 8.13z' fill='%2376767A'/%3E%3C/svg%3E");
    background-color: #fff;

    @include media($sm) {
      margin-right: 14px;
    }

    &:hover {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m17.51 20.13-1.77 1.77-9.9-9.9 9.9-9.9 1.77 1.77L9.38 12l8.13 8.13z' fill='%23181818'/%3E%3C/svg%3E"), linear-gradient(180deg, #ffde00 0%, #fc0 100%);
    }
  }

  .next {
    margin-left: 22px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m6.49 20.13 1.77 1.77 9.9-9.9-9.9-9.9-1.77 1.77L14.62 12l-8.13 8.13z' fill='%2376767A'/%3E%3C/svg%3E");
    background-color: #fff;

    @include media($sm) {
      margin-right: 0;
      margin-left: 14px;
    }

    &:hover {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m6.49 20.13 1.77 1.77 9.9-9.9-9.9-9.9-1.77 1.77L14.62 12l-8.13 8.13z' fill='%23181818'/%3E%3C/svg%3E"), linear-gradient(180deg, #ffde00 0%, #fc0 100%);
    }
  }

  li {
    margin: 0 7px;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.1;
    color: $gray-dolphin;
    cursor: pointer;

    @include media($sm) {
      margin: 0 5px;
    }

    &:hover {
      text-shadow: 0 0 1px $dark;
      color: $dark;
    }

    a {
      display: inline-block;
      padding: 11px 15px;
    }

    &.selected {
      border: 1px solid $gray-ghost;
      font-weight: 700;
      color: $dark;

      &:hover {
        text-shadow: none;
      }
    }
  }

  .hidden + li {
    margin-left: 0;
  }

  &--center {
    display: flex;
    justify-content: center;
  }
}
