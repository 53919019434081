// abstracts
@import "helpers/variables";
@import "helpers/mixins";
@import "helpers/animations";

// design-system
@import "design-system";

// core
@import "core/reset";
@import "core/global";

// components
@import "components/footer";
@import "components/modal";  // TODO: carry forward with your feet
@import "components/to-top";
@import "components/informer";
@import "components/site-rating";
@import "../school-cabinet/possibility-global";

// menu
@import "components/menu-mobile";

body {
  &.body-loading {
    visibility: hidden;
  }
}

.dg-popup {
  position: relative;
  padding: 20px 30px;
}

.dg-popup__close {
  position: absolute;
  right: 10px;
  top: 10px;
  line-height: 1;
}

.icon.icon-close {
  width: 16px;
  height: 16px;
  background-position: -99px -324px;
  cursor: pointer;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  background-image: url("#{$path-img}/sprites/sprite.png");
  background-repeat: no-repeat;
}

.pop-it-up {
  position: fixed !important;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  display: block;
  padding: 20px 20px 60px;
  width: 100%;
  min-height: 100%;
  max-height: 100vh;
  overflow-y: scroll;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0.85);
  -webkit-overflow-scrolling: touch;

  .dg-mobile-menu-anchor__close {
    @include media($xl) {
      display: block;
    }
  }
}

.dg-mobile-menu-anchor__close {
  position: absolute;
  right: 5px;
  top: 5px;
  display: none;
  width: 30px;
  height: 30px;
  border: 2px solid rgba(0, 0, 0, 0.95);
  border-radius: 50%;
  background-color: #f0f9ff;

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 14px;
    height: 2px;
    background-color: #5e7c91;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  // @include media(990px) {
  //     display: block;
  // }
}

.pager {
  display: flex;
  margin: 0 0 20px;
  border-radius: 4px;
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.2);
  background-color: #fff;

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  li {
    margin: 0 4px;
    padding: 8px 4px;

    a {
      display: block;
      padding: 0;
      min-width: 24px;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      text-align: center;
      color: #6f97a3;

      &:hover {
        text-decoration: none;
        color: #000;
      }
    }

    &.selected {
      background-color: #6f97a3;

      a {
        color: #fff;
      }
    }

    &.hidden {
      opacity: 0;

      @media (max-width: 1024px) {
        display: none;
      }
    }
  }

  &__wrapper {
    padding-top: 20px;
  }

  &__color-wrapper {
    .pager {
      margin: 0;
      box-shadow: none;
      background-color: #dae5e8;
    }
  }
}

.a-load-button {
  border-radius: 2px;
  text-decoration: none !important;
  background-image:
    linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.3) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0.3) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 40px 40px !important;
  background-color: #99dd63 !important;
  animation: progress-bar-stripes 2s linear infinite;
  cursor: no-drop;
  pointer-events: none;
}

.v-body-library {
  .header {
    margin: 0 0 20px;
  }
}

.i-formula-kb-tabs .i-kb-img,
.i-kb-img {
  margin: 0;
  width: 33px;
  height: 33px;
  background-image: url("../../img/icons.png");
}

.stickySidebar {
  @include media($xl) {
    position: static !important;
  }
}

.close-banner {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  width: 95px;
  font-size: 10px;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
}

.sidebar-of-max {
  @media (min-width: 1220px) {
    z-index: 1;
    max-height: 1px;
  }
}

@include media($xl) {
  .column--left,
  .column_v_left {
    width: 100% !important;
    min-height: 1px !important;
  }
}

.banner-block {
  &:empty {
    margin-bottom: 0;
  }
}

.banner-hide-on-mobile {
  @media (max-width: 576px) {
    display: none;
  }
}

.rk-block-banner {
  position: relative;
  margin: 0 auto;
  width: fit-content;
}
