.checkbox {
  position: relative;
  display: inline-block;
  padding-left: 16px;
  min-height: 16px;
  user-select: none;
}

.checkbox__input {
  @include visually-hidden;
}

.checkbox__text {
  display: inline-block;
  padding-left: 8px;
  vertical-align: top;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1;
  color: #181818;
  cursor: pointer;
}

.checkbox__mark {
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #76767a;
  border-radius: 3px;
  transition: border-color 200ms, backgroun-color 200ms;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    left: 2px;
    top: 2px;
    display: none;
    width: 12px;
    height: 10px;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m3.727 7.583-2.78-2.78-.947.94L3.727 9.47l8-8-.94-.94-7.06 7.053z' fill='%23fff'/%3E%3C/svg%3E");
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
  }
}

.checkbox:hover,
.checkbox:focus-within {
  .checkbox__mark {
    border-color: #181818;
  }
}

.checkbox__input:checked {
  + .checkbox__mark {
    border-color: #57a216;
    background-color: #57a216;

    &::after {
      display: block;
    }
  }
}

.checkbox__input:disabled,
.checkbox__input.disabled {
  + .checkbox__mark {
    border-color: #adadaf;
    background-color: transparent;
    cursor: not-allowed;

    &::after {
      display: none;
    }
  }

  ~ .checkbox__text {
    color: #adadaf;
    cursor: not-allowed;
  }

  &.checkbox__input:checked {
    + .checkbox__mark {
      background-color: #adadaf;
      cursor: not-allowed;

      &::after {
        display: block;
      }
    }
  }
}
