.ta-informer {
    position: fixed;
    left: 30px;
    bottom: 30px;
    z-index: 99;
    width: 400px;

    &__item {
        position: relative;
        margin: 0 0 8px;
        border: 1px solid #a8c1d3;
        border-radius: 4px;
        padding: 6px 10px 10px;
        box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.1);
        background: #fff;
        transition: all 0.2s ease-out;

        &:hover {
            box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.5);
        }

        &--close {
            position: relative;
            float: right;
            margin: 0 -2px 0 0;
            border-radius: 50%;
            padding: 4px;
            width: 16px;
            height: 16px;
            background-color: #ccc;
            transition: all 0.2s ease-out;

            &::before {
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &::before,
            &::after {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                display: block;
                width: 10px;
                height: 1px;
                background: #fff;
            }

            &::after {
                transform: translate(-50%, -50%) rotate(-45deg);
            }

            &:hover {
                background-color: #f4838d;
            }
        }

        &--mes {
            margin-bottom: 2px;
            font-size: 12px;
            line-height: 1.3;
            color: #a9a9a9;
        }

        &--avatar {
            float: left;
            margin-right: 10px;
            padding-top: 4px;

            & img {
                vertical-align: bottom;
                width: 30px;
                height: 30px;
            }
        }

        &--name {
            font-weight: 300;
            font-size: 16px;
            color: #1987c4;
        }

        &--message {
            color: #333;
        }

        &--time {
            float: right;
            font-size: 12px;
            color: #a9a9a9;
        }
    }
}
