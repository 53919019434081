.checkbox-tag {
  display: inline-block;
  cursor: pointer;
  user-select: none;
}

.checkbox-tag__input {
  @include visually-hidden;
}

.checkbox-tag__text {
  display: inline-block;
  padding: 4px 8px;
  border: 1px solid $gray-dolphin;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: $dark;
  transition: border-color 200ms, background-color 200ms, color 200ms;
}

.checkbox-tag:hover,
.checkbox-tag:focus-within {
  .checkbox-tag__text {
    border-color: $dark;
  }
}

.checkbox-tag__input:checked {
  + .checkbox-tag__text {
    border-color: $dark-cyan;
    color: $white;
    background-color: $dark-cyan;
  }
}

.checkbox-tag__input:disabled,
.checkbox-tag__input.disabled {
  + .checkbox-tag__text {
    border-color: $gray-pearl;
    color: $gray-pearl;
    background-color: $white;
    cursor: not-allowed;
  }
}
