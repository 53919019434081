@import "../helpers/variables";
@import "../helpers/mixins";

.mobile-menu {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;

  &__list {
    display: flex;
    justify-content: space-between;
    padding-right: 18px;
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: 18px;
    box-shadow: rgba($black, 0.1) 0 -2px 10px 0;
    background-color: $green-450;
  }

  &__item {
    display: flex;
    align-items: flex-end;
    padding-top: 5px;
    padding-bottom: 5px;

    &--main {
      align-items: center;
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px 0;
  }

  &__text {
    margin-top: 1px;
    font-size: 13px;
    color: $white;
  }

  @media (min-width: 1231px) {
    display: none;
  }
}