.to-top {
  position: fixed;
  bottom: 70px !important;
  z-index: 99;
  display: none;
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
  width: 48px;
  height: 48px;
  border: 1px solid #57a216;
  border-radius: 5px;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m0 8 1.41 1.41L7 3.83V16h2V3.83l5.59 5.58L16 8 8 0 0 8z' fill='%2376767A'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
  transition: opacity 0.5s, background-color 0.3s;
  cursor: pointer;

  &:hover {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m0 8 1.41 1.41L7 3.83V16h2V3.83l5.59 5.58L16 8 8 0 0 8z' fill='%23fff'/%3E%3C/svg%3E");
    background-color: #57a216;
  }

  @media (max-width: 990px) {
    right: 5px;
    bottom: $height-mobile-menu;
    margin-bottom: calc(env(safe-area-inset-bottom) + 5px);
    width: 40px;
    height: 40px;
    border: 2px solid #72bb4c;
  }

  @media (min-width: 991px) {
    right: 30px;
    bottom: 70px;
    width: 48px;
    height: 48px;
    border: 1px solid #72bb4c;
  }
}

.show-scroll {
  display: block;
}