$sm-height: 32px;
$md-height: 40px;
$lg-height: 50px;
$xl-height: 60px;

.button {
  --deprecated: "WARNING: Использовать .btn";

  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  min-height: $md-height;
  appearance: none;
  border: none;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(70, 131, 17, 0), inset 0 -2px 1px rgba(0, 0, 0, 0.28);
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
  text-align: center;
  color: #fff;
  background-color: #57a216;
  cursor: pointer;

  &:hover {
    box-shadow: 0 3px 9px rgba(70, 131, 17, 0.5), inset 0 -2px 1px rgba(0, 0, 0, 0.28);
    color: #fff;
  }

  &:active {
    box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.3);
    background-color: #57a216;
  }

  &[disabled] {
    box-shadow: none;
    color: #76767a;
    background-image: none;
    background-color: #c8c8c9;
    cursor: not-allowed;

    &:hover {
      box-shadow: none;
      color: #76767a;
      background-image: none;
    }

    &:active {
      box-shadow: none;
      background-image: none;
    }
  }

  svg {
    margin-right: 8px;
    height: 24px;
    max-width: 24px;
  }

  &--sm {
    padding: 0 8px;
    min-height: $sm-height;
    box-shadow: none;
    font-weight: 400;

    &:hover {
      box-shadow: 0 3px 6px rgba(70, 131, 17, 0.3);
    }

    &:active {
      box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.18);
      background-color: #57a216;
    }

    &[disabled] {
      box-shadow: none;
      background-color: #c8c8c9;
    }

    svg {
      margin-right: 8px;
      height: 16px;
      max-width: 16px;
    }

    &.button--accent {
      &:hover {
        box-shadow: 0 3px 9px rgba(191, 154, 0, 0.5);
      }

      &:active {
        box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.2);
        background-image:
          linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.08),
            rgba(255, 255, 255, 0.08)
          ),
          linear-gradient(180deg, #f8d803 0%, #fc0 100%);
      }
    }
  }

  &--lg {
    padding: 0 24px;
    min-height: $lg-height;
    font-weight: 600;
    font-size: 16px;
  }

  &--xl {
    padding: 0 32px;
    min-height: $xl-height;
    font-weight: 600;
    font-size: 18px;
  }

  &--accent {
    color: #000;
    background-image: linear-gradient(180deg, #ffde00 0%, #fc0 100%);

    &:hover {
      box-shadow: 0 3px 9px rgba(191, 154, 0, 0.5), inset 0 -2px 1px rgba(0, 0, 0, 0.28);
      color: #000;
    }

    &:active {
      box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.18);
      background-image: linear-gradient(180deg, #f8d803 0%, #fc0 100%);
    }

    &[disabled] {
      &:hover {
        box-shadow: none;
        background-image: none;
      }
    }
  }

  &--outline {
    border: 1px solid #57a216;
    box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0);
    color: #181818;
    background-image: none;
    background-color: #fff;

    &:hover {
      box-shadow: none;
      color: #fff;
      background-color: #57a216;
    }

    &:active {
      box-shadow: inset 0 -2px 8px rgba(34, 34, 34, 0.35);
      color: #fff;
    }

    &[disabled] {
      border: 1px solid #c8c8c9;
      color: #76767a;
      background-color: #fff;

      &:hover {
        box-shadow: none;
        background-color: #fff;
      }
    }

    &.button {
      &--accent {
        border-color: #ffde00;

        &:hover {
          border-color: #fc0;
          box-shadow: none;
          color: #181818;
          background-image: linear-gradient(180deg, #ffde00 0%, #fc0 100%);

          svg {
            fill: #181818;
          }
        }

        &:active {
          box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.2);
          color: #181818;
          background-image: linear-gradient(180deg, #ffde00 0%, #fc0 100%);
        }

        &[disabled]:hover,
        &[disabled],
        &[disabled]:focus {
          border-color: #c8c8c9;
          box-shadow: none;
          color: #76767a;
          background-image: none;

          svg {
            fill: #76767a;
          }
        }
      }
    }
  }

  &--cancel {
    box-shadow: none;
    color: #ff3d00;
    background-color: transparent;

    &:hover {
      box-shadow: none;
      color: #000;
      background-color: transparent;
    }

    &:active,
    &:focus {
      box-shadow: none;
    }
  }

  &--100 {
    width: 100%;
  }

  &--svg {
    padding: 0;
    width: 40px;

    svg {
      margin: 0;
    }

    &.button--sm {
      width: 32px;
    }

    &.button--lg {
      width: 50px;
    }

    &.button--xl {
      width: 60px;
    }
  }
}
