.radio-button {
  position: relative;
  display: inline-block;
  padding-left: 16px;
  user-select: none;

  &:hover,
  &:focus-within {
    .radio-button__mark {
      border-color: #181818;
    }
  }

  &__input,
  &__checked {
    @include visually-hidden;

    &:disabled,
    &.disabled {
      + .radio-button__mark {
        border-color: #adadaf;
        background-color: transparent;
        cursor: not-allowed;

        &::after {
          display: none;
        }
      }

      ~ .radio-button__text {
        text-shadow: none;
        color: #adadaf;
        cursor: not-allowed;
      }
    }

    &:checked {
      + .radio-button__mark {
        border-color: #57a216;
        color: #57a216;
        background-color: #57a216;

        &::after {
          display: block;
        }
      }
    }
  }

  &__checked,
  &__input {
    &:checked {
      ~ .radio-button__text {
        font-weight: 600;
        font-size: 14px;
        color: #57a216;
      }
    }

    ~ .radio-button__text {
      font-weight: 400;
      color: #181818;
    }
  }

  &__text {
    display: inline-block;
    padding-left: 8px;
    vertical-align: top;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.1;
    color: #181818;
    transition: text-shadow 200ms;
    cursor: pointer;

    @include media($sm) {
      font-size: 12px;
    }
  }

  &__mark {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 1px solid #76767a;
    border-radius: 50%;
    transition: border-color 200ms, backgroun-color 200ms;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      left: 4px;
      top: 4px;
      display: none;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #fff;
    }
  }
}
