.tag-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  height: 27px;
  border-radius: 2px;
  line-height: 1;
  color: #267f8c;
  background-color: rgba(37, 157, 227, 0.1);

  &:hover {
    color: #fff;
    background-color: #267f8c;
  }
}
