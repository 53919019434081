.footer {
  padding: 10px 0 20px;
  background-color: #fff;

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(#000, 0.3);
  }

  &__logo {
    flex-shrink: 0;
    margin-right: 15px;
    max-width: 175px;
    transition: opacity 0.25s ease-out;

    &:hover {
      opacity: 0.8;
    }

    img {
      display: block;
      object-fit: contain;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 14px;
  }

  &__list-item {
    margin-right: 20px;
    line-height: 1.5;

    &:last-child {
      margin-right: 0;
    }
  }

  &__list-link {
    white-space: nowrap;
    color: #000;
    transition: color 0.25s ease-out;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__social {
    display: flex;
    margin-right: 60px;

    @media (max-width: 992px) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  &__social-item {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  &__social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 3px;
    background-color: #fff;
    transition: background-color 0.25s ease-out;

    &:hover {
      background-color: rgba(#57a216, 0.2);
    }
  }

  &__links {
    display: flex;
    justify-content: center;
  }

  &__links-item {
    position: relative;
    margin-right: 60px;
    font-size: 12px;
    text-decoration: underline;
    color: rgba(#000, 0.6);
    transition: color 0.25s ease-out;

    &::after {
      content: "";
      position: absolute;
      right: -30px;
      top: 7px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: rgba(#ff9000, 0.5);
    }

    &:hover {
      text-decoration: none;
      color: #0046b1;
    }

    &:last-child {
      margin-right: 0;

      &::after {
        display: none;
      }
    }
  }

  &__documents {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__document {
    display: flex;
    align-items: center;
    max-width: 368px;

    &:hover {
      .footer__document-text {
        color: #0046b1;
      }
    }

    &:not(:last-child) {
      margin-right: 40px;

      @media (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }

    @media (max-width: 768px) {
      max-width: none;
    }
  }

  &__document-pic {
    margin-right: 12px;
  }

  &__document-text {
    font-size: 12px;
    color: #57a216;
    transition: color 0.25s ease-out;
  }

  &__legal-text {
    display: flex;
    margin-bottom: 32px;

    p {
      width: 100%;
      font-size: 12px;
      line-height: 1.5;
      color: #181818;

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;

    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }
}

@media (max-width: 990px) {
  .footer__top {
    flex-direction: column;
  }

  .footer__logo {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

@media (max-width: 768px) {
  .footer__links {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .footer__links-item {
    margin-right: 0;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .footer__links-item::after {
    right: 50%;
    top: auto;
    bottom: -12px;
    transform: translateX(-2px);
  }
}
