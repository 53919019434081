@charset "UTF-8";
/* Radius */
/* stylelint-disable */
@-webkit-keyframes showHide {
  0%, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@keyframes showHide {
  0%, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
/* stylelint-disable */
/* Radius */
.button {
  --deprecated: "WARNING: Использовать .btn";
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0 16px;
  min-height: 40px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  border-radius: 5px;
  -webkit-box-shadow: 0 3px 6px rgba(70, 131, 17, 0), inset 0 -2px 1px rgba(0, 0, 0, 0.28);
          box-shadow: 0 3px 6px rgba(70, 131, 17, 0), inset 0 -2px 1px rgba(0, 0, 0, 0.28);
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
  text-align: center;
  color: #fff;
  background-color: #57a216;
  cursor: pointer;
}
.button:hover {
  -webkit-box-shadow: 0 3px 9px rgba(70, 131, 17, 0.5), inset 0 -2px 1px rgba(0, 0, 0, 0.28);
          box-shadow: 0 3px 9px rgba(70, 131, 17, 0.5), inset 0 -2px 1px rgba(0, 0, 0, 0.28);
  color: #fff;
}
.button:active {
  -webkit-box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.3);
          box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.3);
  background-color: #57a216;
}
.button[disabled] {
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #76767a;
  background-image: none;
  background-color: #c8c8c9;
  cursor: not-allowed;
}
.button[disabled]:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #76767a;
  background-image: none;
}
.button[disabled]:active {
  -webkit-box-shadow: none;
          box-shadow: none;
  background-image: none;
}
.button svg {
  margin-right: 8px;
  height: 24px;
  max-width: 24px;
}
.button--sm {
  padding: 0 8px;
  min-height: 32px;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-weight: 400;
}
.button--sm:hover {
  -webkit-box-shadow: 0 3px 6px rgba(70, 131, 17, 0.3);
          box-shadow: 0 3px 6px rgba(70, 131, 17, 0.3);
}
.button--sm:active {
  -webkit-box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.18);
          box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.18);
  background-color: #57a216;
}
.button--sm[disabled] {
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: #c8c8c9;
}
.button--sm svg {
  margin-right: 8px;
  height: 16px;
  max-width: 16px;
}
.button--sm.button--accent:hover {
  -webkit-box-shadow: 0 3px 9px rgba(191, 154, 0, 0.5);
          box-shadow: 0 3px 9px rgba(191, 154, 0, 0.5);
}
.button--sm.button--accent:active {
  -webkit-box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.2);
          box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.2);
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(255, 255, 255, 0.08)), to(rgba(255, 255, 255, 0.08))), -webkit-gradient(linear, left top, left bottom, from(#f8d803), to(#fc0));
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), linear-gradient(180deg, #f8d803 0%, #fc0 100%);
}
.button--lg {
  padding: 0 24px;
  min-height: 50px;
  font-weight: 600;
  font-size: 16px;
}
.button--xl {
  padding: 0 32px;
  min-height: 60px;
  font-weight: 600;
  font-size: 18px;
}
.button--accent {
  color: #000;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffde00), to(#fc0));
  background-image: linear-gradient(180deg, #ffde00 0%, #fc0 100%);
}
.button--accent:hover {
  -webkit-box-shadow: 0 3px 9px rgba(191, 154, 0, 0.5), inset 0 -2px 1px rgba(0, 0, 0, 0.28);
          box-shadow: 0 3px 9px rgba(191, 154, 0, 0.5), inset 0 -2px 1px rgba(0, 0, 0, 0.28);
  color: #000;
}
.button--accent:active {
  -webkit-box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.18);
          box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.18);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f8d803), to(#fc0));
  background-image: linear-gradient(180deg, #f8d803 0%, #fc0 100%);
}
.button--accent[disabled]:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
  background-image: none;
}
.button--outline {
  border: 1px solid #57a216;
  -webkit-box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0);
          box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0);
  color: #181818;
  background-image: none;
  background-color: #fff;
}
.button--outline:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #fff;
  background-color: #57a216;
}
.button--outline:active {
  -webkit-box-shadow: inset 0 -2px 8px rgba(34, 34, 34, 0.35);
          box-shadow: inset 0 -2px 8px rgba(34, 34, 34, 0.35);
  color: #fff;
}
.button--outline[disabled] {
  border: 1px solid #c8c8c9;
  color: #76767a;
  background-color: #fff;
}
.button--outline[disabled]:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: #fff;
}
.button--outline.button--accent {
  border-color: #ffde00;
}
.button--outline.button--accent:hover {
  border-color: #fc0;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #181818;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffde00), to(#fc0));
  background-image: linear-gradient(180deg, #ffde00 0%, #fc0 100%);
}
.button--outline.button--accent:hover svg {
  fill: #181818;
}
.button--outline.button--accent:active {
  -webkit-box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.2);
          box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.2);
  color: #181818;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffde00), to(#fc0));
  background-image: linear-gradient(180deg, #ffde00 0%, #fc0 100%);
}
.button--outline.button--accent[disabled]:hover, .button--outline.button--accent[disabled], .button--outline.button--accent[disabled]:focus {
  border-color: #c8c8c9;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #76767a;
  background-image: none;
}
.button--outline.button--accent[disabled]:hover svg, .button--outline.button--accent[disabled] svg, .button--outline.button--accent[disabled]:focus svg {
  fill: #76767a;
}
.button--cancel {
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #ff3d00;
  background-color: transparent;
}
.button--cancel:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #000;
  background-color: transparent;
}
.button--cancel:active, .button--cancel:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.button--100 {
  width: 100%;
}
.button--svg {
  padding: 0;
  width: 40px;
}
.button--svg svg {
  margin: 0;
}
.button--svg.button--sm {
  width: 32px;
}
.button--svg.button--lg {
  width: 50px;
}
.button--svg.button--xl {
  width: 60px;
}
.btn {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  text-align: center;
  cursor: pointer;
}
.btn[disabled] {
  border: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #76767a !important;
  background-image: none !important;
  background-color: #c8c8c9 !important;
  cursor: not-allowed !important;
}
.btn--left-addons svg {
  margin: 0 8px 0 0;
}
.btn--right-addons svg {
  margin: 0 0 0 8px;
}
.btn--sm {
  padding: 0 8px;
  min-height: 32px;
  font-weight: 400;
  font-size: 14px;
}
.btn--md {
  padding: 0 16px;
  min-height: 40px;
  font-weight: 600;
  font-size: 14px;
}
.btn--lg {
  padding: 0 24px;
  min-height: 50px;
  font-weight: 600;
  font-size: 16px;
}
.btn--xl {
  padding: 0 32px;
  min-height: 60px;
  font-weight: 600;
  font-size: 18px;
}
.btn--100 {
  width: 100%;
}
.btn--center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.btn--svg {
  padding: 0;
}
.btn--svg svg {
  margin: 0;
}
.btn--svg.btn--sm {
  width: 32px;
}
.btn--svg.btn--md {
  width: 40px;
}
.btn--svg.btn--lg {
  width: 50px;
}
.btn--svg.btn--xl {
  width: 60px;
}
.btn--base {
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 6px rgba(70, 131, 17, 0), inset 0 -2px 1px rgba(0, 0, 0, 0.28);
          box-shadow: 0 3px 6px rgba(70, 131, 17, 0), inset 0 -2px 1px rgba(0, 0, 0, 0.28);
  line-height: 1.3;
  color: #fff;
  background-color: #57a216;
}
.btn--base:hover {
  -webkit-box-shadow: 0 3px 9px rgba(70, 131, 17, 0.5), inset 0 -2px 1px rgba(0, 0, 0, 0.28);
          box-shadow: 0 3px 9px rgba(70, 131, 17, 0.5), inset 0 -2px 1px rgba(0, 0, 0, 0.28);
  color: #fff;
}
.btn--base:active {
  -webkit-box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.3);
          box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.3);
  color: #fff;
}
.btn--base.btn--sm {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.btn--base.btn--sm:hover {
  -webkit-box-shadow: 0 3px 6px rgba(70, 131, 17, 0.3);
          box-shadow: 0 3px 6px rgba(70, 131, 17, 0.3);
}
.btn--base.btn--sm:active {
  -webkit-box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.18);
          box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.18);
}
.btn--accent {
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 6px rgba(70, 131, 17, 0), inset 0 -2px 1px rgba(0, 0, 0, 0.28);
          box-shadow: 0 3px 6px rgba(70, 131, 17, 0), inset 0 -2px 1px rgba(0, 0, 0, 0.28);
  line-height: 1.3;
  color: #000;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffde00), to(#fc0));
  background-image: linear-gradient(180deg, #ffde00 0%, #fc0 100%);
}
.btn--accent:hover {
  -webkit-box-shadow: 0 3px 9px rgba(191, 154, 0, 0.5), inset 0 -2px 1px rgba(0, 0, 0, 0.28);
          box-shadow: 0 3px 9px rgba(191, 154, 0, 0.5), inset 0 -2px 1px rgba(0, 0, 0, 0.28);
  color: #000;
}
.btn--accent:active {
  -webkit-box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.18);
          box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.18);
  color: #000;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f8d803), to(#fc0));
  background-image: linear-gradient(180deg, #f8d803 0%, #fc0 100%);
}
.btn--accent.btn--sm {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.btn--accent.btn--sm:hover {
  -webkit-box-shadow: 0 3px 9px rgba(191, 154, 0, 0.5);
          box-shadow: 0 3px 9px rgba(191, 154, 0, 0.5);
}
.btn--accent.btn--sm:active {
  -webkit-box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.2);
          box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.2);
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(255, 255, 255, 0.08)), to(rgba(255, 255, 255, 0.08))), -webkit-gradient(linear, left top, left bottom, from(#f8d803), to(#fc0));
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), linear-gradient(180deg, #f8d803 0%, #fc0 100%);
}
.btn--outline-base {
  border: 1px solid #57a216;
  border-radius: 6px;
  -webkit-box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0);
          box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0);
  line-height: 1.3;
  color: #181818;
  background-color: #fff;
}
.btn--outline-base:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #fff;
  background-color: #57a216;
}
.btn--outline-base:active {
  -webkit-box-shadow: inset 0 -2px 8px rgba(34, 34, 34, 0.35);
          box-shadow: inset 0 -2px 8px rgba(34, 34, 34, 0.35);
  color: #fff;
}
.btn--outline-accent {
  border: 1px solid #ffde00;
  border-radius: 6px;
  -webkit-box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0);
          box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0);
  line-height: 1.3;
  color: #181818;
  background-color: #fff;
}
.btn--outline-accent:hover {
  border-color: #fc0;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #181818;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffde00), to(#fc0));
  background-image: linear-gradient(180deg, #ffde00 0%, #fc0 100%);
}
.btn--outline-accent:active {
  -webkit-box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.2);
          box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.2);
  color: #181818;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffde00), to(#fc0));
  background-image: linear-gradient(180deg, #ffde00 0%, #fc0 100%);
}
.btn--cancel {
  border: 1px solid #ff3b30;
  border-radius: 5px;
  color: #ff3b30;
  background-color: transparent;
}
.btn--cancel:hover {
  color: #fff;
  background-color: #ff3b30;
}
.btn--cancel:active, .btn--cancel:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.btn--primary {
  border-radius: 6px;
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  line-height: 1.3;
  color: #fff;
  background-color: #00bc4b;
}
.btn--primary:hover {
  -webkit-box-shadow: 0 0 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
          box-shadow: 0 0 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  color: #fff;
  background-color: #00ad42;
}
.btn--primary:active {
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  color: #fff;
  background-color: #009a36;
}
.btn--alternative {
  border-radius: 6px;
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  line-height: 1.3;
  color: #292929;
  background-color: #f9d357;
}
.btn--alternative:hover {
  -webkit-box-shadow: 0 0 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
          box-shadow: 0 0 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  color: #292929;
  background-color: #f8c630;
}
.btn--alternative:active {
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  color: #292929;
  background-color: #f7b110;
}
.btn--secondary {
  border: 1px solid #00bc4b;
  border-radius: 6px;
  color: #181818;
  background-color: #fff;
}
.btn--secondary:hover {
  border-color: #00bc4b;
  color: #fff;
  background-color: #00bc4b;
}
.btn--secondary:active {
  border-color: #009a36;
  color: #fff;
  background-color: #009a36;
}
.btn--ghost {
  border-radius: 6px;
  color: #424242;
  background-color: #f2f2f2;
}
.btn--ghost:hover {
  -webkit-box-shadow: 0 0 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
          box-shadow: 0 0 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  color: #424242;
  background-color: #f2f2f2;
}
.btn--ghost:active {
  color: #424242;
  background-color: #dbdbdb;
}
.btn--reject {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 -2px 1px rgba(0, 0, 0, 0.28);
          box-shadow: inset 0 -2px 1px rgba(0, 0, 0, 0.28);
  background-color: #ff3d00;
}
.btn--reject:hover {
  -webkit-box-shadow: 0 3px 9px rgba(255, 61, 0, 0.5), inset 0 -2px 1px rgba(0, 0, 0, 0.28);
          box-shadow: 0 3px 9px rgba(255, 61, 0, 0.5), inset 0 -2px 1px rgba(0, 0, 0, 0.28);
  background-color: #ff3d00;
}
.btn--reject:active {
  -webkit-box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.3);
          box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.3);
  background-color: #ff3d00;
}
.btn.a-load-button {
  text-decoration: none !important;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.3) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.3) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
  background-color: #99dd63 !important;
  -webkit-animation: progress-bar-stripes 2s linear infinite;
          animation: progress-bar-stripes 2s linear infinite;
  cursor: no-drop;
  pointer-events: none;
}
.link {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  color: #267f8c;
  cursor: pointer;
}
.link:hover {
  color: #259de3;
}
.link:active {
  color: #267f8c;
}
.link svg {
  height: 16px;
  min-width: 16px;
}
.link--disabled {
  color: #c8c8c9;
  cursor: not-allowed;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.link--disabled:hover {
  color: #c8c8c9;
}
.link--underline {
  text-decoration: underline;
}
.link--underline:active, .link--underline:focus {
  text-decoration: none;
}
.link--icon svg {
  margin-right: 4px;
}
.link--icon-right svg {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  margin: 0 0 0 4px;
}
.link--xs {
  font-size: 12px;
}
.link--sm {
  font-size: 14px;
}
.link--lg {
  font-size: 16px;
}
.link--xl {
  font-size: 18px;
}
.radio-button {
  position: relative;
  display: inline-block;
  padding-left: 16px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.radio-button:hover .radio-button__mark, .radio-button:focus-within .radio-button__mark {
  border-color: #181818;
}
.radio-button__input, .radio-button__checked {
  position: absolute;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}
.radio-button__input:disabled + .radio-button__mark, .radio-button__input.disabled + .radio-button__mark, .radio-button__checked:disabled + .radio-button__mark, .radio-button__checked.disabled + .radio-button__mark {
  border-color: #adadaf;
  background-color: transparent;
  cursor: not-allowed;
}
.radio-button__input:disabled + .radio-button__mark::after, .radio-button__input.disabled + .radio-button__mark::after, .radio-button__checked:disabled + .radio-button__mark::after, .radio-button__checked.disabled + .radio-button__mark::after {
  display: none;
}
.radio-button__input:disabled ~ .radio-button__text, .radio-button__input.disabled ~ .radio-button__text, .radio-button__checked:disabled ~ .radio-button__text, .radio-button__checked.disabled ~ .radio-button__text {
  text-shadow: none;
  color: #adadaf;
  cursor: not-allowed;
}
.radio-button__input:checked + .radio-button__mark, .radio-button__checked:checked + .radio-button__mark {
  border-color: #57a216;
  color: #57a216;
  background-color: #57a216;
}
.radio-button__input:checked + .radio-button__mark::after, .radio-button__checked:checked + .radio-button__mark::after {
  display: block;
}
.radio-button__checked:checked ~ .radio-button__text, .radio-button__input:checked ~ .radio-button__text {
  font-weight: 600;
  font-size: 14px;
  color: #57a216;
}
.radio-button__checked ~ .radio-button__text, .radio-button__input ~ .radio-button__text {
  font-weight: 400;
  color: #181818;
}
.radio-button__text {
  display: inline-block;
  padding-left: 8px;
  vertical-align: top;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1;
  color: #181818;
  -webkit-transition: text-shadow 200ms;
  transition: text-shadow 200ms;
  cursor: pointer;
}
@media only screen and (max-width: 576px) {
  .radio-button__text {
    font-size: 12px;
  }
}
.radio-button__mark {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #76767a;
  border-radius: 50%;
  -webkit-transition: border-color 200ms, backgroun-color 200ms;
  transition: border-color 200ms, backgroun-color 200ms;
  cursor: pointer;
}
.radio-button__mark::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 4px;
  display: none;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #fff;
}
.checkbox {
  position: relative;
  display: inline-block;
  padding-left: 16px;
  min-height: 16px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.checkbox__input {
  position: absolute;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}
.checkbox__text {
  display: inline-block;
  padding-left: 8px;
  vertical-align: top;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1;
  color: #181818;
  cursor: pointer;
}
.checkbox__mark {
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #76767a;
  border-radius: 3px;
  -webkit-transition: border-color 200ms, backgroun-color 200ms;
  transition: border-color 200ms, backgroun-color 200ms;
  cursor: pointer;
}
.checkbox__mark::after {
  content: "";
  position: absolute;
  left: 2px;
  top: 2px;
  display: none;
  width: 12px;
  height: 10px;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m3.727 7.583-2.78-2.78-.947.94L3.727 9.47l8-8-.94-.94-7.06 7.053z' fill='%23fff'/%3E%3C/svg%3E");
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
}
.checkbox:hover .checkbox__mark,
.checkbox:focus-within .checkbox__mark {
  border-color: #181818;
}
.checkbox__input:checked + .checkbox__mark {
  border-color: #57a216;
  background-color: #57a216;
}
.checkbox__input:checked + .checkbox__mark::after {
  display: block;
}
.checkbox__input:disabled + .checkbox__mark,
.checkbox__input.disabled + .checkbox__mark {
  border-color: #adadaf;
  background-color: transparent;
  cursor: not-allowed;
}
.checkbox__input:disabled + .checkbox__mark::after,
.checkbox__input.disabled + .checkbox__mark::after {
  display: none;
}
.checkbox__input:disabled ~ .checkbox__text,
.checkbox__input.disabled ~ .checkbox__text {
  color: #adadaf;
  cursor: not-allowed;
}
.checkbox__input:disabled.checkbox__input:checked + .checkbox__mark,
.checkbox__input.disabled.checkbox__input:checked + .checkbox__mark {
  background-color: #adadaf;
  cursor: not-allowed;
}
.checkbox__input:disabled.checkbox__input:checked + .checkbox__mark::after,
.checkbox__input.disabled.checkbox__input:checked + .checkbox__mark::after {
  display: block;
}
.checkbox-tag {
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.checkbox-tag__input {
  position: absolute;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}
.checkbox-tag__text {
  display: inline-block;
  padding: 4px 8px;
  border: 1px solid #76767a;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: #181818;
  -webkit-transition: border-color 200ms, background-color 200ms, color 200ms;
  transition: border-color 200ms, background-color 200ms, color 200ms;
}
.checkbox-tag:hover .checkbox-tag__text,
.checkbox-tag:focus-within .checkbox-tag__text {
  border-color: #181818;
}
.checkbox-tag__input:checked + .checkbox-tag__text {
  border-color: #267f8c;
  color: #fff;
  background-color: #267f8c;
}
.checkbox-tag__input:disabled + .checkbox-tag__text,
.checkbox-tag__input.disabled + .checkbox-tag__text {
  border-color: #adadaf;
  color: #adadaf;
  background-color: #fff;
  cursor: not-allowed;
}
.input {
  padding: 9px;
  width: 100%;
  border: 1px solid #adadaf;
  border-radius: 5px;
  font-size: 14px;
  line-height: 1;
  color: #181818;
  background-color: #fff;
}
.input:hover {
  padding: 9px;
  border: 1px solid #181818;
}
.input:active, .input:focus {
  padding: 7px;
  outline: none;
  border: 3px solid #267f8c;
}
.input--disabled, .input[disabled] {
  border-color: rgba(118, 118, 122, 0.5);
  cursor: not-allowed;
}
.input--disabled:active, .input--disabled:focus, .input--disabled:hover, .input[disabled]:active, .input[disabled]:focus, .input[disabled]:hover {
  padding: 9px;
  border: 1px solid #adadaf;
}
.input--disabled::-webkit-input-placeholder, .input[disabled]::-webkit-input-placeholder {
  color: rgba(118, 118, 122, 0.5);
}
.input--disabled::-moz-placeholder, .input[disabled]::-moz-placeholder {
  color: rgba(118, 118, 122, 0.5);
}
.input--disabled:-ms-input-placeholder, .input[disabled]:-ms-input-placeholder {
  color: rgba(118, 118, 122, 0.5);
}
.input--disabled::-ms-input-placeholder, .input[disabled]::-ms-input-placeholder {
  color: rgba(118, 118, 122, 0.5);
}
.input--disabled::placeholder, .input[disabled]::placeholder {
  color: rgba(118, 118, 122, 0.5);
}
.input::-webkit-input-placeholder {
  color: #76767a;
}
.input::-moz-placeholder {
  color: #76767a;
}
.input:-ms-input-placeholder {
  color: #76767a;
}
.input::-ms-input-placeholder {
  color: #76767a;
}
.input::placeholder {
  color: #76767a;
}
.input--error {
  padding: 7px;
  border: 3px solid #ff3d00;
  background-color: #ffece5;
}
.input--error:hover {
  padding: 7px;
  border: 3px solid #267f8c;
}
.input--error:active, .input--error:focus {
  padding: 7px;
  outline: none;
  border: 3px solid #267f8c;
}
.input--success {
  border: 3px solid #57a216;
}
.input--success:hover {
  border: 3px solid #267f8c;
}
.input--success:active, .input--success:focus {
  padding: 8px;
  outline: none;
  border: 3px solid #267f8c;
}
.input--sm {
  padding: 6px;
  font-size: 14px;
}
.input--sm:hover {
  padding: 6px;
}
.input--sm:active, .input--sm:focus {
  padding: 4px;
}
.input--sm.input--disabled:active, .input--sm.input--disabled:focus, .input--sm.input--disabled:hover, .input--sm[disabled]:active, .input--sm[disabled]:focus, .input--sm[disabled]:hover {
  padding: 6px;
}
.input--sm.input--error:active, .input--sm.input--error:focus {
  padding: 6px;
}
.input--sm.input--success:active, .input--sm.input--success:focus {
  padding: 6px;
}
.input--lg {
  padding: 13px;
  font-size: 16px;
}
.input--lg:hover {
  padding: 13px;
}
.input--lg:active, .input--lg:focus {
  padding: 11px;
}
.input--lg.input--disabled:active, .input--lg.input--disabled:focus, .input--lg.input--disabled:hover, .input--lg[disabled]:active, .input--lg[disabled]:focus, .input--lg[disabled]:hover {
  padding: 13px;
}
.input--lg.input--error:active, .input--lg.input--error:focus {
  padding: 13px;
}
.input--lg.input--success:active, .input--lg.input--success:focus {
  padding: 13px;
}
.multiselect {
  display: inline-block;
  min-width: 234px;
}
.multiselect__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 4px;
  padding: 11px 8px;
  width: 100%;
  border: 1px solid #c8c8c9;
  border-radius: 5px;
  background-color: #fff;
  -webkit-transition: border-color 100ms;
  transition: border-color 100ms;
  cursor: pointer;
}
.multiselect__details {
  display: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 8px;
  padding: 0 1px 0 6px;
  border-radius: 16px;
  background-color: #267f8c;
}
.multiselect__number {
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: #fff;
}
.multiselect__clear {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.2.807a.664.664 0 0 0-.94 0L5 4.06 1.74.8a.664.664 0 1 0-.94.94L4.06 5 .8 8.26a.664.664 0 1 0 .94.94L5 5.94 8.26 9.2a.664.664 0 1 0 .94-.94L5.94 5 9.2 1.74a.668.668 0 0 0 0-.933z' fill='%23fff'/%3E%3C/svg%3E");
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  -webkit-transition: background-color 100ms;
  transition: background-color 100ms;
}
.multiselect__clear:hover {
  background-color: rgba(24, 24, 24, 0.3);
}
.multiselect__title {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin-right: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: #181818;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.multiselect__arrow {
  display: inline-block;
  width: 13px;
  height: 8px;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.58.327.4 1.507l6.6 6.6 6.6-6.6-1.18-1.18L7 5.747 1.58.327z' fill='%2376767A'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
}
.multiselect__menu {
  position: absolute;
  z-index: 1;
  display: none;
  min-width: 234px;
  max-height: 250px;
  overflow-y: auto;
  border: 2px solid #267f8c;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 8px rgba(34, 34, 34, 0.1);
          box-shadow: 0 2px 8px rgba(34, 34, 34, 0.1);
  background-color: #fff;
}
/* --- Checkbox --- */
.multiselect__checkbox {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 9px 8px 9px 24px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.multiselect__checkbox-text {
  display: inline-block;
  padding-left: 8px;
  vertical-align: top;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1;
  color: #181818;
}
.multiselect__checkbox-input {
  position: absolute;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}
.multiselect__checkbox-mark {
  position: absolute;
  left: 8px;
  top: 8px;
  width: 16px;
  height: 16px;
  border: 1px solid #76767a;
  border-radius: 3px;
  -webkit-transition: border-color 100ms, backgroun-color 100ms;
  transition: border-color 100ms, backgroun-color 100ms;
  cursor: pointer;
}
.multiselect__checkbox-mark::after {
  content: "";
  position: absolute;
  left: 2px;
  top: 2px;
  display: none;
  width: 12px;
  height: 10px;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m3.727 7.583-2.78-2.78-.947.94L3.727 9.47l8-8-.94-.94-7.06 7.053z' fill='%23fff'/%3E%3C/svg%3E");
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
}
.multiselect__checkbox:hover,
.multiselect__checkbox:focus-within {
  outline: none;
  background-color: #267f8c;
}
.multiselect__checkbox:hover .multiselect__checkbox-mark,
.multiselect__checkbox:focus-within .multiselect__checkbox-mark {
  border-color: #fff;
}
.multiselect__checkbox:hover .multiselect__checkbox-text,
.multiselect__checkbox:focus-within .multiselect__checkbox-text {
  color: #fff;
}
.multiselect__checkbox-input:checked + .multiselect__checkbox-mark {
  border-color: #57a216;
  background-color: #57a216;
}
.multiselect__checkbox-input:checked + .multiselect__checkbox-mark::after {
  display: block;
}
/* --- Tags --- */
.multiselect-tags__item {
  display: inline-block;
  margin: 0 4px 4px 0;
  padding: 2px 22px 4px 8px;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 5px rgba(24, 24, 24, 0.25);
          box-shadow: inset 0 0 5px rgba(24, 24, 24, 0.25);
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.2 3.807a.664.664 0 0 0-.94 0L8 7.06 4.74 3.8a.664.664 0 1 0-.94.94L7.06 8 3.8 11.26a.664.664 0 1 0 .94.94L8 8.94l3.26 3.26a.664.664 0 1 0 .94-.94L8.94 8l3.26-3.26a.668.668 0 0 0 0-.933z' fill='%23fff'/%3E%3C/svg%3E");
  background-position: center right 4px;
  background-size: auto;
  background-repeat: no-repeat;
  background-color: #267f8c;
  cursor: pointer;
}
.multiselect__button:hover {
  border-color: #76767a;
}
.multiselect__button:hover .multiselect__arrow {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.58.327.4 1.507l6.6 6.6 6.6-6.6-1.18-1.18L7 5.747 1.58.327z' fill='%23181818'/%3E%3C/svg%3E");
}
.multiselect.active .multiselect__menu {
  display: block;
}
.multiselect.active .multiselect__arrow {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.multiselect.checked {
  min-width: 260px;
}
.multiselect.checked .multiselect__menu {
  min-width: 260px;
}
.multiselect__details.active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.multiselect--disabled {
  cursor: not-allowed;
}
.multiselect--disabled .multiselect__button {
  pointer-events: none;
}
.multiselect--disabled .multiselect__title {
  color: #adadaf;
}
.select {
  position: relative;
  width: 178px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: #181818;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.select__default {
  position: absolute;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}
.select__button {
  position: relative;
  padding: 0 25px 0 8px;
  width: 100%;
  overflow: hidden;
  border: 1px solid #c8c8c9;
  border-radius: 5px;
  line-height: 38px;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
  background-color: #fff;
  -webkit-transition: border-color 200ms;
  transition: border-color 200ms;
  cursor: pointer;
}
.select__button::after {
  content: "";
  position: absolute;
  right: 9px;
  top: 15px;
  display: inline-block;
  width: 14px;
  height: 9px;
  line-height: 1;
  background-image: url("data:image/svg+xml;utf8,<svg width='14' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.58.327L.4 1.507l6.6 6.6 6.6-6.6-1.18-1.18L7 5.747 1.58.327z' fill='grey'/></svg>");
}
.select__button:hover, .select__button:focus, .select__button.open {
  outline-color: #267f8c;
  border-color: #76767a;
}
.select__button:hover::after, .select__button:focus::after, .select__button.open::after {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.58.327.4 1.507l6.6 6.6 6.6-6.6-1.18-1.18L7 5.747 1.58.327z' fill='%23181818'/%3E%3C/svg%3E");
}
.select__button.open::after {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.select__list {
  position: absolute;
  left: 0;
  top: 44px;
  z-index: 1;
  display: none;
  margin-top: 4px;
  padding-bottom: 8px;
  width: 100%;
  max-height: 230px;
  overflow-y: auto;
  border: 2px solid #267f8c;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 8px rgba(34, 34, 34, 0.1);
          box-shadow: 0 2px 8px rgba(34, 34, 34, 0.1);
  background-color: #fff;
}
.select__list:empty {
  display: none !important;
  /* stylelint-disable-line declaration-no-important */
}
.select__list.open {
  display: block;
}
.select__option {
  position: relative;
  padding: 8px 7px;
  line-height: 1.1;
  color: #181818;
  cursor: pointer;
}
.select__option:hover {
  color: #fff;
  background-color: #267f8c;
}
.select__option.checked {
  font-weight: 600;
  color: #181818;
}
.select__option.checked:hover {
  font-weight: 400;
  color: #fff;
  background-color: #267f8c;
}
.select__empty-result {
  display: block;
  margin: auto;
  padding: 48px 5px 64px;
  max-width: 300px;
  font-size: 16px;
  line-height: 1.4;
  text-align: center;
  color: #76767a;
}
.select.disabled {
  color: #adadaf;
  cursor: not-allowed;
}
.select.disabled .select__button {
  border-color: #adadaf;
  color: #adadaf;
  pointer-events: none;
}
.select.disabled .select__button::after {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.58.327.4 1.507l6.6 6.6 6.6-6.6-1.18-1.18L7 5.747 1.58.327z' fill='%23ADADAF'/%3E%3C/svg%3E");
}
.select.disabled .select__list {
  display: none;
}
.select--sm {
  width: 178px;
  font-size: 12px;
}
.select--sm .select__button {
  padding-right: 21px;
  padding-left: 4px;
  line-height: 30px;
}
.select--sm .select__button::after {
  right: 6px;
  top: 12px;
}
.select--sm .select__list {
  top: 36px;
}
.select--sm .select__option {
  padding: 8px 4px;
}
.select--lg {
  width: 189px;
  font-size: 16px;
}
.select--lg .select__button {
  line-height: 50px;
}
.select--lg .select__button::after {
  top: 22px;
}
.select--lg .select__list {
  top: 58px;
}
.select--lg .select__option:first-child {
  margin-top: 8px;
}
.select--search {
  width: 323px;
}
.select--search .select__button {
  padding: 0 50px 0 16px;
}
.select--search .select__button::after {
  display: none;
}
.select--search .select__button:hover, .select--search .select__button:focus, .select--search .select__button.open {
  outline: none;
}
.select--search .select__button.open + .select__button-search {
  border-radius: 4px 0 0 4px;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.select--search .select__button-search {
  position: absolute;
  right: 1px;
  top: 1px;
  margin: 0;
  padding: 0;
  width: 48px;
  height: 38px;
  border-radius: 0 4px 4px 0;
  background-image: url("data:image/svg+xml;utf8,<svg width='14' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.58.327L.4 1.507l6.6 6.6 6.6-6.6-1.18-1.18L7 5.747 1.58.327z' fill='grey'/></svg>");
  background-position: center;
  background-repeat: no-repeat;
  background-color: #e3e3e3;
}
.select--search-lg .select__button {
  line-height: 48px;
}
.select--search-lg .select__button-search {
  height: 48px;
}
.select--search-lg .select__list {
  top: 54px;
}
.pager-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.pager-pagination ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media only screen and (max-width: 768px) {
  .pager-pagination ul {
    overflow-x: auto;
  }
}
.pager-pagination .first,
.pager-pagination .last {
  display: none;
}
.pager-pagination .previous,
.pager-pagination .next {
  width: 40px;
  height: 40px;
  border: 1px solid #ffde00;
  border-radius: 5px;
  font-size: 0;
  text-indent: -9999px;
  background-position: center;
  background-repeat: no-repeat;
}
.pager-pagination .previous:hover,
.pager-pagination .next:hover {
  background-position: center;
  background-repeat: no-repeat;
}
.pager-pagination .previous a,
.pager-pagination .next a {
  display: block;
  width: 100%;
  height: 100%;
}
.pager-pagination .previous.hidden,
.pager-pagination .next.hidden {
  display: none;
}
.pager-pagination .previous {
  margin-right: 22px;
  margin-left: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m17.51 20.13-1.77 1.77-9.9-9.9 9.9-9.9 1.77 1.77L9.38 12l8.13 8.13z' fill='%2376767A'/%3E%3C/svg%3E");
  background-color: #fff;
}
@media only screen and (max-width: 576px) {
  .pager-pagination .previous {
    margin-right: 14px;
  }
}
.pager-pagination .previous:hover {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m17.51 20.13-1.77 1.77-9.9-9.9 9.9-9.9 1.77 1.77L9.38 12l8.13 8.13z' fill='%23181818'/%3E%3C/svg%3E"), -webkit-gradient(linear, left top, left bottom, from(#ffde00), to(#fc0));
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m17.51 20.13-1.77 1.77-9.9-9.9 9.9-9.9 1.77 1.77L9.38 12l8.13 8.13z' fill='%23181818'/%3E%3C/svg%3E"), linear-gradient(180deg, #ffde00 0%, #fc0 100%);
}
.pager-pagination .next {
  margin-left: 22px;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m6.49 20.13 1.77 1.77 9.9-9.9-9.9-9.9-1.77 1.77L14.62 12l-8.13 8.13z' fill='%2376767A'/%3E%3C/svg%3E");
  background-color: #fff;
}
@media only screen and (max-width: 576px) {
  .pager-pagination .next {
    margin-right: 0;
    margin-left: 14px;
  }
}
.pager-pagination .next:hover {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m6.49 20.13 1.77 1.77 9.9-9.9-9.9-9.9-1.77 1.77L14.62 12l-8.13 8.13z' fill='%23181818'/%3E%3C/svg%3E"), -webkit-gradient(linear, left top, left bottom, from(#ffde00), to(#fc0));
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m6.49 20.13 1.77 1.77 9.9-9.9-9.9-9.9-1.77 1.77L14.62 12l-8.13 8.13z' fill='%23181818'/%3E%3C/svg%3E"), linear-gradient(180deg, #ffde00 0%, #fc0 100%);
}
.pager-pagination li {
  margin: 0 7px;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.1;
  color: #76767a;
  cursor: pointer;
}
@media only screen and (max-width: 576px) {
  .pager-pagination li {
    margin: 0 5px;
  }
}
.pager-pagination li:hover {
  text-shadow: 0 0 1px #181818;
  color: #181818;
}
.pager-pagination li a {
  display: inline-block;
  padding: 11px 15px;
}
.pager-pagination li.selected {
  border: 1px solid #c8c8c9;
  font-weight: 700;
  color: #181818;
}
.pager-pagination li.selected:hover {
  text-shadow: none;
}
.pager-pagination .hidden + li {
  margin-left: 0;
}
.pager-pagination--center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.tooltip-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}
.tooltip-box svg {
  margin-left: 4px;
}
.balance-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 16px 24px 24px;
  border-radius: 8px;
  -webkit-box-shadow: 0 5px 4px 2px rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 4px 2px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}
@media only screen and (max-width: 768px) {
  .balance-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
  }
}
.balance-box__yellow {
  background-color: #fffce7;
}
.balance-box__sum span {
  font-weight: 700;
}
.balance-box__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: calc(100% - 235px);
}
@media only screen and (max-width: 768px) {
  .balance-box__body {
    margin-top: 15px;
    width: 100%;
  }
}
.balance-box__hours {
  margin-bottom: 16px;
  font-size: 18px;
}
.balance-box__hours span {
  font-weight: 700;
  font-size: 24px;
  color: #f60;
}
.balance-box__subtitle {
  display: block;
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.2;
}
.balance-box__discount {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 10px;
}
.tag-link {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0 8px;
  height: 27px;
  border-radius: 2px;
  line-height: 1;
  color: #267f8c;
  background-color: rgba(37, 157, 227, 0.1);
}
.tag-link:hover {
  color: #fff;
  background-color: #267f8c;
}
html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
}
*,
*::after,
*::before {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}
ul,
ol,
fieldset {
  padding: 0;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}
ul,
li {
  list-style: none;
}
img {
  display: inline-block;
  max-width: 100%;
}
a {
  text-decoration: none;
  color: inherit;
}
input,
button,
textarea,
select {
  font: inherit;
}
button {
  border-color: transparent;
  cursor: pointer;
}
address {
  font-style: normal;
}
mark {
  background-color: transparent;
}
html {
  margin: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
}
body {
  color: #181818;
  background-color: #f5f5f5;
}
@media (max-width: 1230px) {
  body {
    padding-bottom: 57px;
  }
}
input,
textarea,
select {
  border-radius: 5px;
}
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder, select::-webkit-input-placeholder {
  color: #76767a;
}
input::-moz-placeholder, textarea::-moz-placeholder, select::-moz-placeholder {
  color: #76767a;
}
input:-ms-input-placeholder, textarea:-ms-input-placeholder, select:-ms-input-placeholder {
  color: #76767a;
}
input::-ms-input-placeholder, textarea::-ms-input-placeholder, select::-ms-input-placeholder {
  color: #76767a;
}
input::placeholder,
textarea::placeholder,
select::placeholder {
  color: #76767a;
}
input {
  padding: 5px 8px;
  width: 100%;
  border: 1px solid #adadaf;
}
.container {
  position: relative;
  margin: 0 auto;
  padding: 0 10px;
  max-width: 1248px;
}
.container--content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 1230px) {
  .container--content {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.column {
  width: 100%;
}
.column--left {
  width: 100%;
}
@media (min-width: 1230px) {
  .column--left {
    margin-right: 20px;
    min-width: 188px;
    max-width: 188px;
  }
}
.column--right {
  margin-left: 20px;
  width: 100%;
  max-width: 292px;
}
@media (max-width: 1230px) {
  .column--right {
    margin-left: 0;
    max-width: 100%;
  }
}
.column--jump-hater {
  max-width: 708px;
}
@media (max-width: 1230px) {
  .column--jump-hater {
    max-width: none;
  }
}
.column--overflow {
  overflow: auto;
}
.visually-hidden {
  position: absolute;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  white-space: nowrap;
  clip: rect(0, 0, 0, 0);
}
.mb20 {
  margin-bottom: 20px;
}
.footer {
  padding: 10px 0 20px;
  background-color: #fff;
}
.footer__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 24px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.footer__logo {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  margin-right: 15px;
  max-width: 175px;
  -webkit-transition: opacity 0.25s ease-out;
  transition: opacity 0.25s ease-out;
}
.footer__logo:hover {
  opacity: 0.8;
}
.footer__logo img {
  display: block;
  -o-object-fit: contain;
     object-fit: contain;
}
.footer__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
}
.footer__list-item {
  margin-right: 20px;
  line-height: 1.5;
}
.footer__list-item:last-child {
  margin-right: 0;
}
.footer__list-link {
  white-space: nowrap;
  color: #000;
  -webkit-transition: color 0.25s ease-out;
  transition: color 0.25s ease-out;
}
.footer__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.footer__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 60px;
}
@media (max-width: 992px) {
  .footer__social {
    margin-right: 0;
    margin-bottom: 16px;
  }
}
.footer__social-item:not(:last-child) {
  margin-right: 16px;
}
.footer__social-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: background-color 0.25s ease-out;
  transition: background-color 0.25s ease-out;
}
.footer__social-link:hover {
  background-color: rgba(87, 162, 22, 0.2);
}
.footer__links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.footer__links-item {
  position: relative;
  margin-right: 60px;
  font-size: 12px;
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.6);
  -webkit-transition: color 0.25s ease-out;
  transition: color 0.25s ease-out;
}
.footer__links-item::after {
  content: "";
  position: absolute;
  right: -30px;
  top: 7px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: rgba(255, 144, 0, 0.5);
}
.footer__links-item:hover {
  text-decoration: none;
  color: #0046b1;
}
.footer__links-item:last-child {
  margin-right: 0;
}
.footer__links-item:last-child::after {
  display: none;
}
.footer__documents {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 32px;
}
@media (max-width: 768px) {
  .footer__documents {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.footer__document {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 368px;
}
.footer__document:hover .footer__document-text {
  color: #0046b1;
}
.footer__document:not(:last-child) {
  margin-right: 40px;
}
@media (max-width: 768px) {
  .footer__document:not(:last-child) {
    margin-right: 0;
    margin-bottom: 16px;
  }
}
@media (max-width: 768px) {
  .footer__document {
    max-width: none;
  }
}
.footer__document-pic {
  margin-right: 12px;
}
.footer__document-text {
  font-size: 12px;
  color: #57a216;
  -webkit-transition: color 0.25s ease-out;
  transition: color 0.25s ease-out;
}
.footer__legal-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 32px;
}
.footer__legal-text p {
  width: 100%;
  font-size: 12px;
  line-height: 1.5;
  color: #181818;
}
.footer__legal-text p:not(:last-child) {
  margin-right: 8px;
}
.footer__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 1200px) {
  .footer__bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
@media (max-width: 990px) {
  .footer__top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .footer__logo {
    margin-right: 0;
    margin-bottom: 15px;
  }
}
@media (max-width: 768px) {
  .footer__links {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
  }

  .footer__links-item {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .footer__links-item:last-child {
    margin-bottom: 0;
  }

  .footer__links-item::after {
    right: 50%;
    top: auto;
    bottom: -12px;
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px);
  }
}
.a-di-vind {
  position: fixed;
  z-index: 9999999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
.a-di-non-vind {
  z-index: 1001;
  margin: 0 auto;
  width: 100%;
  height: auto;
  max-width: 100%;
  border-radius: 6px;
  background-color: #fff;
}
.a-di-non-vind .a-di-top {
  padding: 10px 20px;
  border-radius: 6px 6px 0 0;
  background-color: #67ab31;
}
.a-di-non-vind .a-di-top p {
  margin: 0;
  margin-right: 40px;
  color: #fff;
}
.a-di-non-vind .a-di-top div.a-di-r,
.a-di-non-vind .a-di-top p.a-di-r {
  position: relative;
  float: right;
  margin: 0;
  margin-top: -5px;
}
.a-di-non-vind .a-di-top div.a-di-r a,
.a-di-non-vind .a-di-top p.a-di-r a {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 4px;
  color: #fff;
}
.a-di-non-vind .a-di-top div.a-di-r a:hover,
.a-di-non-vind .a-di-top p.a-di-r a:hover {
  text-decoration: none;
  color: inherit;
  background-color: #67ab31;
}
.a-di-non-vind .a-di-top div.a-di-r a .a-x,
.a-di-non-vind .a-di-top p.a-di-r a .a-x {
  right: 0;
  top: 4px;
  opacity: 0.8;
  margin: 0;
}
.a-di-non-vind .a-di-top div.a-di-r a .a-x:hover,
.a-di-non-vind .a-di-top p.a-di-r a .a-x:hover {
  opacity: 1;
}
.a-di-non-vind .a-di-top div.a-di-r a.a-non-b,
.a-di-non-vind .a-di-top p.a-di-r a.a-non-b {
  padding: 0;
  background-image: none;
}
.a-di-non-vind .a-di-top div.a-di-r a.a-non-b:hover,
.a-di-non-vind .a-di-top p.a-di-r a.a-non-b:hover {
  text-decoration: none;
  color: inherit;
  background-image: none;
}
.a-di-non-vind .a-di-top div.a-di-r ul.a-di-auto,
.a-di-non-vind .a-di-top p.a-di-r ul.a-di-auto {
  position: absolute;
  right: 65px;
  display: inline-block;
  margin-top: 0;
  padding: 0;
  width: 120px;
  height: 20px;
  vertical-align: top;
  overflow: hidden;
  border-radius: 4px;
  background-color: #67ab31;
  cursor: pointer;
}
.a-di-non-vind .a-di-top div.a-di-r ul.a-di-auto:hover,
.a-di-non-vind .a-di-top p.a-di-r ul.a-di-auto:hover {
  position: absolute;
  z-index: 9;
  height: 121px;
}
.a-di-non-vind .a-di-top div.a-di-r ul.a-di-auto li,
.a-di-non-vind .a-di-top p.a-di-r ul.a-di-auto li {
  display: block;
  margin-bottom: 5px;
}
.a-di-non-vind .a-di-top div.a-di-r ul.a-di-auto li a,
.a-di-non-vind .a-di-top p.a-di-r ul.a-di-auto li a {
  display: block;
  width: 100%;
}
.a-di-non-vind .a-di-top div.a-di-r ul.a-di-auto li a:hover,
.a-di-non-vind .a-di-top p.a-di-r ul.a-di-auto li a:hover {
  background-color: #67ab31;
}
.a-di-non-vind .a-di-top div.a-di-r ul.a-di-auto li.a-pading,
.a-di-non-vind .a-di-top p.a-di-r ul.a-di-auto li.a-pading {
  margin-bottom: 10px;
  text-align: center;
}
.a-di-non-vind .a-otvet-tabl {
  padding: 25px;
}
@media (max-width: 768px) {
  .a-di-non-vind {
    z-index: 9999999;
    max-height: 100vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .a-di-non-vind input {
    outline: none;
  }
}
@media (max-width: 768px) {
  .a-di-non-vind {
    -webkit-box-shadow: 0 0 8px 5px rgba(225, 235, 227, 0.38) inset;
            box-shadow: 0 0 8px 5px rgba(225, 235, 227, 0.38) inset;
    background-size: 13%;
  }
}
@media (max-width: 768px) {
  .a-di-non-vind {
    margin: 0 !important;
    width: 100% !important;
    max-width: 100%;
    border-radius: 0;
  }
  .a-di-non-vind .a-di-top {
    border-radius: 0;
  }
  .a-di-non-vind .a-di-top .dr-di-l {
    margin-right: 70px;
    overflow: hidden;
  }
  .a-di-non-vind .a-di-top .dr-di-l b {
    float: left;
    margin-right: 10px;
  }
  .a-di-non-vind .a-di-top .dr-di-l span {
    float: left;
  }
  .a-di-non-vind .a-info-fechka {
    display: block;
    max-width: 100%;
  }
  .a-di-non-vind .a-wiz-center .row input,
.a-di-non-vind .a-wiz-center .row select,
.a-di-non-vind .a-wiz-center .row textarea {
    width: 100%;
  }
  .a-di-non-vind .a-wiz-center .blok label.a-label {
    width: calc(100% - 60px);
  }
  .a-di-non-vind .buttom {
    padding: 6px;
    max-width: 100%;
    white-space: normal;
  }
  .a-di-non-vind .a-dopinfo-fechka {
    display: block;
    max-width: 100%;
  }
}
@media (max-width: 400px) {
  .a-di-non-vind a.a-fechka-dobavit {
    float: left;
    margin-top: -10px;
    padding: 6px 15px;
  }
  .a-di-non-vind .a-wiz-center .blok label.a-label {
    padding-top: 0;
  }
}
.tabs-login {
  position: relative;
  -webkit-box-shadow: 0 0 8px 5px rgba(225, 235, 227, 0.38) inset;
          box-shadow: 0 0 8px 5px rgba(225, 235, 227, 0.38) inset;
}
.tabs-login h3 {
  margin: 0;
  text-align: center;
}
.tabs-login hr {
  margin-top: 15px;
  margin-bottom: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #f3f3f3ff;
  border-bottom-color: #fff;
}
.tabs-login .a-form-login {
  margin: 0 auto 15px;
  padding: 15px;
  width: 720px;
  max-width: 100%;
  border: 1px solid #d1d1d1ff;
  border-radius: 4px;
  -webkit-box-shadow: 0 4px 6px -2px #b5b3b3, 0 0 0 12px rgba(255, 255, 255, 0.6);
          box-shadow: 0 4px 6px -2px #b5b3b3, 0 0 0 12px rgba(255, 255, 255, 0.6);
  background-color: #fff;
}
.tabs-login .a-form-login .form form ul {
  margin: 5px 0 0;
  padding-left: 14px;
}
.tabs-login .a-form-login .form form ul li {
  margin-bottom: 5px;
}
.tabs-login .a-form-login .form form ul li a {
  color: #0a7fbb;
}
.tabs-login .a-form-login .form form .column_2_1_1 p.note {
  float: right;
  margin-right: 10px;
  color: #b8b8b8;
}
.tabs-login .a-form-login .form form label {
  display: inline-block;
  padding-right: 20px;
  width: 100px;
  text-align: right;
}
.tabs-login .a-form-login .form form input,
.tabs-login .a-form-login .form form select {
  display: inline-block;
  width: 200px;
  height: 36px;
}
.tabs-login .a-form-login .form form input.buttom,
.tabs-login .a-form-login .form form select.buttom {
  float: right;
  margin-top: 0;
  margin-right: 9px;
}
.tabs-login .a-form-login .form form input[type=checkbox] {
  display: inline-block;
  width: 20px;
}
.tabs-login .a-form-login .form form p {
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .tabs-login .a-form-login label {
    margin-bottom: 5px;
    width: auto !important;
  }
  .tabs-login .a-form-login select,
.tabs-login .a-form-login input:not(.buttom) {
    width: 100% !important;
  }
  .tabs-login .a-form-login .buttons {
    text-align: center;
  }
  .tabs-login .a-form-login .buttons .buttom {
    float: none !important;
  }
}
.tabs-login .view {
  padding: 15px;
}
.tabs-login ul.tabs {
  position: relative;
  display: block;
  margin: 0 auto 10px;
  padding: 15px 0 6px;
  width: 394px;
  height: 67px;
}
.tabs-login ul.tabs li {
  list-style: none;
}
.tabs-login ul.tabs li:first-of-type a {
  margin-right: 10px;
}
.tabs-login ul.tabs a {
  float: left;
  margin: 0 0 10px;
  padding: 9px 4px;
  width: 192px;
  height: 36px;
  border: 0;
  border-radius: 4px;
  white-space: nowrap;
  text-align: center;
  color: #fff;
  background: rgba(255, 255, 255, 0.3);
}
.tabs-login ul.tabs a:hover {
  text-decoration: none;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}
.tabs-login ul.tabs a.active {
  position: relative;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
  color: #333f;
  background-color: #fff;
}
.tabs-login ul.tabs a.active::before {
  content: "";
  position: absolute;
  left: 42%;
  top: 52px;
  width: 0;
  height: 0;
  border-top: 15px solid #67ab31;
  border-right: 15px solid rgba(255, 255, 255, 0);
  border-bottom: 15px solid rgba(255, 255, 255, 0);
  border-left: 15px solid rgba(255, 255, 255, 0);
}
.tabs-login ul.tabs a.active:hover {
  color: #333f;
  background-color: #fff;
  cursor: context-menu;
}
@media (max-width: 768px) {
  .tabs-login ul.tabs {
    width: 192px;
    height: 110px;
  }
  .tabs-login ul.tabs::before {
    content: "";
    position: absolute;
    left: 42%;
    bottom: -30px;
    width: 0;
    height: 0;
    border-top: 15px solid #67ab31;
    border-right: 15px solid rgba(255, 255, 255, 0);
    border-bottom: 15px solid rgba(255, 255, 255, 0);
    border-left: 15px solid rgba(255, 255, 255, 0);
  }
  .tabs-login ul.tabs a.active::before {
    display: none;
  }
  .tabs-login ul.tabs li:first-of-type a {
    margin: 0 0 8px;
  }
}
.tabs-login.dr-tabs-login {
  padding: 50px 0 35px;
}
@media (max-width: 768px) {
  .tabs-login {
    padding-bottom: 60px;
    -webkit-box-shadow: none;
            box-shadow: none;
    background-image: none;
  }
}
.dr-login-reg {
  position: absolute;
  display: block;
  width: 100%;
  height: 67px;
  background-color: #67ab31;
}
@media (max-width: 768px) {
  .dr-login-reg {
    height: 110px;
  }
}
.dr-close-modal {
  position: absolute;
  right: 15px;
  top: 15px;
  padding: 0;
  width: 20px;
  height: 20px;
  border: none;
  background-color: transparent;
  -webkit-transition: -webkit-transform 0.25s ease-out;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
  cursor: pointer;
}
.dr-close-modal::before {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  width: 100%;
  height: 1px;
  background-color: #fff;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.dr-close-modal::after {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  width: 100%;
  height: 1px;
  background-color: #fff;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.dr-close-modal:hover {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.dg-sb-wrapper {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 4px;
}
.dg-wrapper__white {
  background-color: #fff;
}
.ta-registation__title {
  margin-bottom: 14px;
  font-weight: 700;
  text-align: center;
}
.error input,
.error select,
.error textarea,
input.error,
select.error,
textarea.error,
.form input.error {
  border: 1px solid #ea9991;
}
.errorSummary,
.errorMessage {
  padding: 10px 0;
  font-size: 12px;
  line-height: 18px;
  color: #dd3e31;
}
.errorSummary p,
.errorMessage p {
  margin: 3px 0;
}
.errorSummary ul,
.errorMessage ul {
  margin: 0;
  padding: 0 16px;
}
.errorSummary ul li,
.errorMessage ul li {
  list-style-type: disc;
  font-weight: 900;
}
.errorSummary ul li a,
.errorMessage ul li a {
  color: #0a7fbb;
}
.st-input__title,
label.st-input-label {
  display: inline-block;
  margin-bottom: 4px;
  font-weight: 700;
}
@media (min-width: 501px) {
  .ta-registation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@media (min-width: 501px) {
  .ta-registation__box:first-child {
    margin-right: 14px;
  }
}
@media (min-width: 501px) {
  .ta-registation__box {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
}
.ta-registation__note {
  font-size: 12px;
}
.kr-login {
  margin: auto;
  max-width: 300px;
}
.ta-registation__lost-pass {
  padding-bottom: 14px;
  border-bottom: 1px solid #ebebeb;
  text-align: center;
}
.ta-registation__sub-title {
  text-align: center;
  color: #b3b3b3;
}
.to-top {
  position: fixed;
  bottom: 70px !important;
  z-index: 99;
  display: none;
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
  width: 48px;
  height: 48px;
  border: 1px solid #57a216;
  border-radius: 5px;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m0 8 1.41 1.41L7 3.83V16h2V3.83l5.59 5.58L16 8 8 0 0 8z' fill='%2376767A'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
  -webkit-transition: opacity 0.5s, background-color 0.3s;
  transition: opacity 0.5s, background-color 0.3s;
  cursor: pointer;
}
.to-top:hover {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m0 8 1.41 1.41L7 3.83V16h2V3.83l5.59 5.58L16 8 8 0 0 8z' fill='%23fff'/%3E%3C/svg%3E");
  background-color: #57a216;
}
@media (max-width: 990px) {
  .to-top {
    right: 5px;
    bottom: 57px;
    margin-bottom: calc(env(safe-area-inset-bottom) + 5px);
    width: 40px;
    height: 40px;
    border: 2px solid #72bb4c;
  }
}
@media (min-width: 991px) {
  .to-top {
    right: 30px;
    bottom: 70px;
    width: 48px;
    height: 48px;
    border: 1px solid #72bb4c;
  }
}
.show-scroll {
  display: block;
}
.ta-informer {
  position: fixed;
  left: 30px;
  bottom: 30px;
  z-index: 99;
  width: 400px;
}
.ta-informer__item {
  position: relative;
  margin: 0 0 8px;
  border: 1px solid #a8c1d3;
  border-radius: 4px;
  padding: 6px 10px 10px;
  -webkit-box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.1);
  background: #fff;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.ta-informer__item:hover {
  -webkit-box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.5);
          box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.5);
}
.ta-informer__item--close {
  position: relative;
  float: right;
  margin: 0 -2px 0 0;
  border-radius: 50%;
  padding: 4px;
  width: 16px;
  height: 16px;
  background-color: #ccc;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.ta-informer__item--close::before {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
          transform: translate(-50%, -50%) rotate(45deg);
}
.ta-informer__item--close::before, .ta-informer__item--close::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  width: 10px;
  height: 1px;
  background: #fff;
}
.ta-informer__item--close::after {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
}
.ta-informer__item--close:hover {
  background-color: #f4838d;
}
.ta-informer__item--mes {
  margin-bottom: 2px;
  font-size: 12px;
  line-height: 1.3;
  color: #a9a9a9;
}
.ta-informer__item--avatar {
  float: left;
  margin-right: 10px;
  padding-top: 4px;
}
.ta-informer__item--avatar img {
  vertical-align: bottom;
  width: 30px;
  height: 30px;
}
.ta-informer__item--name {
  font-weight: 300;
  font-size: 16px;
  color: #1987c4;
}
.ta-informer__item--message {
  color: #333;
}
.ta-informer__item--time {
  float: right;
  font-size: 12px;
  color: #a9a9a9;
}
/* Radius */
/* stylelint-disable */
.site-rating-button {
  position: fixed;
  right: 24px;
  bottom: 0;
  z-index: 1030;
  padding: 12px;
  outline: none;
  border: none;
  border-radius: 6px 6px 0 0;
  -webkit-box-shadow: -2px 2px 11px rgba(0, 0, 0, 0.08);
          box-shadow: -2px 2px 11px rgba(0, 0, 0, 0.08);
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #57a216;
  background-color: #fff;
}
@media only screen and (max-width: 1230px) {
  .site-rating-button {
    right: 0;
    bottom: calc(50% + 60px);
    -webkit-transform: rotate(-90deg) translate3d(0, 2px, 0);
            transform: rotate(-90deg) translate3d(0, 2px, 0);
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
  }
}
@media screen and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 576px) {
  .site-rating-button {
    bottom: calc(50% + 100px);
  }
}
.possibility {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.possibility__item {
  position: relative;
  margin-bottom: 20px;
  border-radius: 6px;
  -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  color: #000;
  background: #fff;
}
.possibility__close {
  position: absolute;
  right: 7px;
  top: 3px;
  width: 10px;
  height: 10px;
  color: #000;
}
.possibility__close:hover {
  color: #000;
}
.possibility__item--active {
  background: #ffcd00;
}
.possibility__item--active .possibility__block {
  height: auto;
}
.possibility__item--new-homeworks .possibility__info {
  padding: 30px 20px 20px;
}
.possibility__item--new-homeworks .possibility__title {
  padding: 13px 20px;
}
.possibility__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid #e6b900;
}
.possibility__top .possibility__title {
  border-bottom: none;
}
.possibility__online {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 10px;
  margin-right: 20px;
  border-radius: 6px;
  padding: 3px 5px;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.35;
  color: #fff;
  background-color: #f00;
}
.possibility__online img {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-preferred-size: 10px;
      flex-basis: 10px;
  margin-right: 5px;
  -webkit-animation-name: onlineOccupation;
          animation-name: onlineOccupation;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.possibility__title {
  border-bottom: 1px solid #d9d9d9;
  padding: 30px 20px 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25;
  text-transform: uppercase;
}
.possibility__new {
  display: inline-block;
  margin-top: 5px;
  margin-right: 15px;
  margin-bottom: 5px;
  border-radius: 6px;
  padding: 3px 10px;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.4;
  color: #fff;
  background-color: #ff8a00;
}
.possibility__block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 20px;
  height: calc(100% - 60px);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.possibility__block--flex-start {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.possibility__icon {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-preferred-size: 94px;
      flex-basis: 94px;
  margin-right: 20px;
}
.possibility__occupation {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.6;
  text-transform: uppercase;
}
.possibility__subtitle {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.4;
}
.possibility__subtitle--new {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.6;
  text-transform: uppercase;
  color: #ff8a00;
}
.possibility__text {
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.375;
  color: #666;
}
.possibility__btn {
  display: inline-block;
  border-radius: 6px;
  padding: 7px 20px;
  font-weight: 700;
  font-size: 13px;
  line-height: 1.4;
  text-transform: uppercase;
  color: #1987c4;
  background-color: #e0f1fd;
}
.possibility__btn:hover {
  color: #1987c4;
  background-color: #b0dbfa;
}
.possibility__btn--disabled {
  color: #000 !important;
  background-color: #999 !important;
  cursor: not-allowed;
}
.possibility__info--padding {
  padding: 20px;
}
.possibility__list {
  font-size: 16px;
  line-height: 1.5;
}
.possibility__list li {
  position: relative;
  margin-bottom: 8px;
  padding-left: 28px;
}
.possibility__list li::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: #1987c4;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.possibility__list a {
  font-weight: 600;
  color: #1987c4;
}
.possibility__subject {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.4;
  text-transform: uppercase;
}
.possibility__teacher {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 15px;
}
.possibility__avatar {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-preferred-size: 36px;
      flex-basis: 36px;
  margin-right: 14px;
}
.possibility__teacher-info p {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.375;
}
.possibility__teacher-info span {
  display: block;
  font-weight: 700;
  text-transform: uppercase;
}
.possibility__connect {
  display: inline-block;
  border-radius: 6px;
  padding: 7px 20px;
  font-weight: 700;
  font-size: 13px;
  line-height: 1.4;
  text-transform: uppercase;
  color: #fff;
  background-color: #1987c4;
}
.possibility__connect:hover {
  background-color: #136897;
}
@-webkit-keyframes onlineOccupation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes onlineOccupation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@media (max-width: 1100px) {
  .possibility__icon {
    -ms-flex-preferred-size: 90px;
        flex-basis: 90px;
    margin-right: 15px;
  }
}
@media (max-width: 880px) {
  .possibility__block {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    height: auto;
  }

  .possibility__icon {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    margin-right: 0;
    margin-bottom: 10px;
    text-align: center;
  }

  .possibility__info {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
  }

  .possibility__top {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .possibility__top .possibility__title {
    padding-right: 5px;
  }

  .possibility__online {
    width: 30px;
  }
  .possibility__online span {
    display: none;
  }
}
@media (max-width: 640px) {
  .possibility__item {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
  }
}
@media (max-width: 990px) {
  #d-ask-msg-popup {
    display: none;
  }
}
/* Radius */
/* stylelint-disable */
.mobile-menu {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}
.mobile-menu__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-right: 18px;
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: 18px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0 -2px 10px 0;
          box-shadow: rgba(0, 0, 0, 0.1) 0 -2px 10px 0;
  background-color: #509901;
}
.mobile-menu__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  padding-top: 5px;
  padding-bottom: 5px;
}
.mobile-menu__item--main {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.mobile-menu__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 6px 0;
}
.mobile-menu__text {
  margin-top: 1px;
  font-size: 13px;
  color: #fff;
}
@media (min-width: 1231px) {
  .mobile-menu {
    display: none;
  }
}
body.body-loading {
  visibility: hidden;
}
.dg-popup {
  position: relative;
  padding: 20px 30px;
}
.dg-popup__close {
  position: absolute;
  right: 10px;
  top: 10px;
  line-height: 1;
}
.icon.icon-close {
  width: 16px;
  height: 16px;
  background-position: -99px -324px;
  cursor: pointer;
}
.icon {
  display: inline-block;
  vertical-align: middle;
  background-image: url("../../img//sprites/sprite.png");
  background-repeat: no-repeat;
}
.pop-it-up {
  position: fixed !important;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  display: block;
  padding: 20px 20px 60px;
  width: 100%;
  min-height: 100%;
  max-height: 100vh;
  overflow-y: scroll;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0.85);
  -webkit-overflow-scrolling: touch;
}
@media only screen and (max-width: 1230px) {
  .pop-it-up .dg-mobile-menu-anchor__close {
    display: block;
  }
}
.dg-mobile-menu-anchor__close {
  position: absolute;
  right: 5px;
  top: 5px;
  display: none;
  width: 30px;
  height: 30px;
  border: 2px solid rgba(0, 0, 0, 0.95);
  border-radius: 50%;
  background-color: #f0f9ff;
}
.dg-mobile-menu-anchor__close::before, .dg-mobile-menu-anchor__close::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 14px;
  height: 2px;
  background-color: #5e7c91;
}
.dg-mobile-menu-anchor__close::before {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
          transform: translate(-50%, -50%) rotate(45deg);
}
.dg-mobile-menu-anchor__close::after {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
}
.pager {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 20px;
  border-radius: 4px;
  -webkit-box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.2);
          box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}
.pager ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto;
}
.pager li {
  margin: 0 4px;
  padding: 8px 4px;
}
.pager li a {
  display: block;
  padding: 0;
  min-width: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #6f97a3;
}
.pager li a:hover {
  text-decoration: none;
  color: #000;
}
.pager li.selected {
  background-color: #6f97a3;
}
.pager li.selected a {
  color: #fff;
}
.pager li.hidden {
  opacity: 0;
}
@media (max-width: 1024px) {
  .pager li.hidden {
    display: none;
  }
}
.pager__wrapper {
  padding-top: 20px;
}
.pager__color-wrapper .pager {
  margin: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: #dae5e8;
}
.a-load-button {
  border-radius: 2px;
  text-decoration: none !important;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.3) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.3) 75%, transparent 75%, transparent) !important;
  background-size: 40px 40px !important;
  background-color: #99dd63 !important;
  -webkit-animation: progress-bar-stripes 2s linear infinite;
          animation: progress-bar-stripes 2s linear infinite;
  cursor: no-drop;
  pointer-events: none;
}
.v-body-library .header {
  margin: 0 0 20px;
}
.i-formula-kb-tabs .i-kb-img,
.i-kb-img {
  margin: 0;
  width: 33px;
  height: 33px;
  background-image: url("../../img/icons.png");
}
@media only screen and (max-width: 1230px) {
  .stickySidebar {
    position: static !important;
  }
}
.close-banner {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  width: 95px;
  font-size: 10px;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
}
@media (min-width: 1220px) {
  .sidebar-of-max {
    z-index: 1;
    max-height: 1px;
  }
}
@media only screen and (max-width: 1230px) {
  .column--left,
.column_v_left {
    width: 100% !important;
    min-height: 1px !important;
  }
}
.banner-block:empty {
  margin-bottom: 0;
}
@media (max-width: 576px) {
  .banner-hide-on-mobile {
    display: none;
  }
}
.rk-block-banner {
  position: relative;
  margin: 0 auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
/*# sourceMappingURL=main.css.map */
