.multiselect {
  display: inline-block;
  min-width: 234px;
}

.multiselect__button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  padding: 11px 8px;
  width: 100%;
  border: 1px solid $gray-ghost;
  border-radius: 5px;
  background-color: $white;
  transition: border-color 100ms;
  cursor: pointer;
}

.multiselect__details {
  display: none;
  align-items: center;
  margin-right: 8px;
  padding: 0 1px 0 6px;
  border-radius: 16px;
  background-color: $dark-cyan;
}

.multiselect__number {
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: $white;
}

.multiselect__clear {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.2.807a.664.664 0 0 0-.94 0L5 4.06 1.74.8a.664.664 0 1 0-.94.94L4.06 5 .8 8.26a.664.664 0 1 0 .94.94L5 5.94 8.26 9.2a.664.664 0 1 0 .94-.94L5.94 5 9.2 1.74a.668.668 0 0 0 0-.933z' fill='%23fff'/%3E%3C/svg%3E");
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  transition: background-color 100ms;

  &:hover {
    background-color: rgba(24, 24, 24, 0.3);
  }
}

.multiselect__title {
  flex-grow: 1;
  margin-right: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: $dark;
  user-select: none;
}

.multiselect__arrow {
  display: inline-block;
  width: 13px;
  height: 8px;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.58.327.4 1.507l6.6 6.6 6.6-6.6-1.18-1.18L7 5.747 1.58.327z' fill='%2376767A'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
}

.multiselect__menu {
  position: absolute;
  z-index: 1;
  display: none;
  min-width: 234px;
  max-height: 250px;
  overflow-y: auto;
  border: 2px solid $dark-cyan;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(34, 34, 34, 0.1);
  background-color: $white;
}

/* --- Checkbox --- */
.multiselect__checkbox {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 9px 8px 9px 24px;
  cursor: pointer;
  user-select: none;
}

.multiselect__checkbox-text {
  display: inline-block;
  padding-left: 8px;
  vertical-align: top;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1;
  color: $dark;
}

.multiselect__checkbox-input {
  @include visually-hidden;
}

.multiselect__checkbox-mark {
  position: absolute;
  left: 8px;
  top: 8px;
  width: 16px;
  height: 16px;
  border: 1px solid $gray-dolphin;
  border-radius: 3px;
  transition: border-color 100ms, backgroun-color 100ms;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    left: 2px;
    top: 2px;
    display: none;
    width: 12px;
    height: 10px;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m3.727 7.583-2.78-2.78-.947.94L3.727 9.47l8-8-.94-.94-7.06 7.053z' fill='%23fff'/%3E%3C/svg%3E");
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
  }
}

.multiselect__checkbox:hover,
.multiselect__checkbox:focus-within {
  outline: none;
  background-color: $dark-cyan;

  .multiselect__checkbox-mark {
    border-color: $white;
  }

  .multiselect__checkbox-text {
    color: $white;
  }
}

.multiselect__checkbox-input:checked {
  + .multiselect__checkbox-mark {
    border-color: $main;
    background-color: $main;
  }

  + .multiselect__checkbox-mark::after {
    display: block;
  }
}

/* --- Tags --- */
.multiselect-tags__item {
  display: inline-block;
  margin: 0 4px 4px 0;
  padding: 2px 22px 4px 8px;
  border-radius: 3px;
  box-shadow: inset 0 0 5px rgba(24, 24, 24, 0.25);
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: $white;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.2 3.807a.664.664 0 0 0-.94 0L8 7.06 4.74 3.8a.664.664 0 1 0-.94.94L7.06 8 3.8 11.26a.664.664 0 1 0 .94.94L8 8.94l3.26 3.26a.664.664 0 1 0 .94-.94L8.94 8l3.26-3.26a.668.668 0 0 0 0-.933z' fill='%23fff'/%3E%3C/svg%3E");
  background-position: center right 4px;
  background-size: auto;
  background-repeat: no-repeat;
  background-color: $dark-cyan;
  cursor: pointer;
}

// hover
.multiselect__button:hover {
  border-color: $gray-dolphin;

  .multiselect__arrow {
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.58.327.4 1.507l6.6 6.6 6.6-6.6-1.18-1.18L7 5.747 1.58.327z' fill='%23181818'/%3E%3C/svg%3E");
  }
}

// active
.multiselect.active {
  .multiselect__menu {
    display: block;
  }

  .multiselect__arrow {
    transform: rotate(-180deg);
  }
}

// change width, when something was checked
.multiselect.checked {
  min-width: 260px;

  .multiselect__menu {
    min-width: 260px;
  }
}

.multiselect__details.active {
  display: flex;
}

// disabled
.multiselect--disabled {
  cursor: not-allowed;

  .multiselect__button {
    pointer-events: none;
  }

  .multiselect__title {
    color: $gray-pearl;
  }
}