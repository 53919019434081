.input {
  padding: 9px;
  width: 100%;
  border: 1px solid #adadaf;
  border-radius: 5px;
  font-size: 14px;
  line-height: 1;
  color: #181818;
  background-color: #fff;

  &:hover {
    padding: 9px;
    border: 1px solid #181818;
  }

  &:active,
  &:focus {
    padding: 7px;
    outline: none;
    border: 3px solid #267f8c;
  }

  &--disabled,
  &[disabled] {
    border-color: rgba(#76767a, 0.5);
    cursor: not-allowed;

    &:active,
    &:focus,
    &:hover {
      padding: 9px;
      border: 1px solid #adadaf;
    }

    &::placeholder {
      color: rgba(#76767a, 0.5);
    }
  }

  &::placeholder {
    color: #76767a;
  }

  &--error {
    padding: 7px;
    border: 3px solid #ff3d00;
    background-color: #ffece5;

    &:hover {
      padding: 7px;
      border: 3px solid #267f8c;
    }

    &:active,
    &:focus {
      padding: 7px;
      outline: none;
      border: 3px solid #267f8c;
    }
  }

  &--success {
    border: 3px solid #57a216;

    &:hover {
      border: 3px solid #267f8c;
    }

    &:active,
    &:focus {
      padding: 8px;
      outline: none;
      border: 3px solid #267f8c;
    }
  }

  &--sm {
    padding: 6px;
    font-size: 14px;

    &:hover {
      padding: 6px;
    }

    &:active,
    &:focus {
      padding: 4px;
    }

    &.input--disabled,
    &[disabled] {
      &:active,
      &:focus,
      &:hover {
        padding: 6px;
      }
    }

    &.input--error {
      &:active,
      &:focus {
        padding: 6px;
      }
    }

    &.input--success {
      &:active,
      &:focus {
        padding: 6px;
      }
    }
  }

  &--lg {
    padding: 13px;
    font-size: 16px;

    &:hover {
      padding: 13px;
    }

    &:active,
    &:focus {
      padding: 11px;
    }

    &.input--disabled,
    &[disabled] {
      &:active,
      &:focus,
      &:hover {
        padding: 13px;
      }
    }

    &.input--error {
      &:active,
      &:focus {
        padding: 13px;
      }
    }

    &.input--success {
      &:active,
      &:focus {
        padding: 13px;
      }
    }
  }
}
